import React, {} from "react";
//import {useTranslation} from "react-i18next";
import { BtnPopDev, clickUrl, preJS, PreJSX } from "../../AppExPf/AppUtil";
import { lang2t, langIsEn, useUILang, validLang } from "../../AppExPf/utils/useUILang";
import { _pathAssign } from "../../consts/ATConstReact";
import { minVal, toAry, toInt, toObj } from "../../libs/libType";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { ReduxBind } from "../../saga/ReduxState";
import CpActionButton from "../_components/CpActionButton";
import CpDropdown, { } from "../_components/CpDropdown";
//import { EPUITime3 } from "../_components/CpEpTabHead";
import CpIco, { CpLangIco } from "../_components/CpIco";
import { CpChangeDeadlineCTA, } from "./LayerAssignmentDeadline";
import { CpDeleteCTA } from "./LayerAssignmentDelete";
import { CpEndAssignmentCTA } from "./LayerAssignmentEnd";
import { CpPublishCTA } from "./LayerAssignmentPublish";
import { CpShareCTA } from "./LayerAssignmentShare";

import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT } from "../../consts/ATConstsAssignment";
import CpAssignToTag from "../_components/CpAssignToTag";

import DetailsCTA from "../../poc/screens/pages/included/assignment/button/DetailsCTA";
import { asmStateEnded } from "./TabTeacherAssignmentSettings";
import { CpChangeSettingsCTA } from "./CpExerciseEditAssignCTA";
import { getT2bEnd, getT2bSrt } from "./CpAssignmentList";
import { TagThrProgressTag } from "./Tags/TagThrProgressTag";
import { TagAsmMarkingTag, exerHasMark } from "./Tags/TagMarkingTag";

// All the logic please refer to figma
const CpTeacherAssignmentCard = (props) => { //base on poc TeacherAssignmentCard
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {layout, yearClass2d} = props; 

    const onRmAsm = props.reload;

    const asm = toObj(props.asm);
    const Exer = toObj(asm.Exer);
    const Stat = toObj(asm.Stat);
    const AName =  lang2t(asm.ALang, Exer.ENameEn, Exer.ENameCt);

    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);
    const WTtl = toInt(Stat.ttl);
    const WSubmits = minVal(WTtl, toInt(Stat.submit));
    const WMarks = minVal(WSubmits, toInt(Stat.mark));

    const btnProps = {...props, t, asm, onRmAsm};
    const RenderActionButtons = <GetTeacherActionButton {...btnProps} />; 
    const RenderActionButtonMore = <GetTeacherButtonMore {...btnProps} />;  
    const renderAssignToTag = <CpAssignToTag {...{yearClass2d, asm}} />;  

    const marking = toAry(asm.marking);
    const cardProps = {...props, RenderActionButtonMore, RenderActionButtons, renderAssignToTag, 
        asm, AName, Exer, Stat, hasA, hasM, WTtl, WSubmits, WMarks, marking, };
    return layout === 'column'? <CardAssignmentTCol {...cardProps} />: <CardAssignmentTList {...cardProps} />;
};

const CardAssignmentTList = props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { asm, AName, Exer, Stat, hasA, hasM, WTtl, WSubmits, WMarks,} = props;

    const AStartTime = getT2bSrt(asm);
    const AEndTime = getT2bEnd(asm);
    
    return <>
    <BtnPopDev txt='asm' >{preJS(asm, 3)}</BtnPopDev>
    <div className={"d-flex flex-column border rounded-light sequenceListAnimation"}>
    <div className={`d-flex px-3 justify-content-between align-items-center px-3 py-2 bg-dim-100`}>
        <span className={`d-flex`}>
            <TagThrProgressTag AState={asm.AState} hasM={hasM}/>
        </span>
        <span className={`d-flex gap-2`}>
            {props.RenderActionButtons}
            {props.RenderActionButtonMore}
    </span>
    </div>
    <div className={`d-flex flex-row justify-content-between gap-2 w-100 px-3 py-2`}>
    <span className={"d-flex flex-column justify-content-between"}>
        <span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn mt-1"}
            style={{width: "1.5rem", height: "1.5rem"}}>
            {CpLangIco(langIsEn(asm.ALang))}
        </span>
        <span className={"d-flex justify-content-center align-items-start rounded fs-4 text-light mt-1"}
            style={{width: "1.5rem", height: "1.5rem"}}>
        </span>
    </span>
        <span className={"d-flex flex-column justify-content-between flex-grow-1"}>
        <span className={"mb-2"}>
            <div className={"semi-bold d-flex justify-content-between align-items-start fs-6 mt-1 mb-2"}>
                <span>{AName}</span>
            </div>
            <div className={"d-flex justify-content-between align-items-start gap-3"}>
                <span className={``}>
                    <span className={`d-flex flex-column flex-md-row gap-2`}>
                    <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                        <span className={"semi-bold pe-2"}>{t("assignment-info-start")}:</span>
                        {/*<span>{formatedDateTime(date.start)}</span>*/}
                        <span>{AStartTime}</span>
                    </span>
                    <span className={"me-3 fs-8"}>
                        <span className={"semi-bold pe-2"}>{t("assignment-info-end")}:</span>
                        {/*<span>{formatedDateTime(date.end)}</span>*/}
                        <span>{AEndTime}</span>
                    </span>
                    </span>
                    <span className={`d-flex flex-wrap gap-2 mt-2`}>
                        {props.renderAssignToTag}
                        <TagAsmMarkingTag {...{asm}}/>
                    </span>
                </span>
                <span className={`d-flex flex-column flex-md-row gap-3`}>
                    <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                        <span className={`semi-bold fs-8`}>{t("assignment-info-submission")}</span>
                        <span className={`d-flex align-items-end gap-2 semi-bold`}>
                            <span className={`fs-4 line-height-normal ${''? "": "text-tag-success"}`}> {WSubmits}</span>
                            <span>/</span>
                            <span>{WTtl}</span>
                        </span>
                    </span>
                    {hasM? <>
                        <span className={"vr d-none d-md-block"}/>
                        <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                        <span className={`semi-bold fs-8 text-nowrap`}>
                            {t("assignment-info-manual-marked")}
                        </span>
                        <span className={`d-flex align-items-end gap-2 semi-bold`}>
                            <span className={`fs-4 line-height-normal ${ WMarks < WSubmits ? "text-tag-danger": "text-tag-success"}`}>{ WMarks}</span>
                            <span>/</span>
                            <span>{WSubmits}</span>
                        </span>
                    </span>
                    </>: ''}
                </span>
            </div>
        </span>
    </span>
    </div>
</div></>;
//    {preJS(asm, 3)}
};


const CardAssignmentTCol = props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {renderAssignToTag, 
        asm, AName, Exer, Stat, hasA, hasM, WTtl, WSubmits, WMarks, marking } = props;

    const AStartTime = getT2bSrt(asm);
    const AEndTime = getT2bEnd(asm);

    return <div className={"d-flex flex-column border rounded-light sequenceListAnimation bg-dim-100"}>
            <div className={`d-flex flex-row justify-content-between gap-2 w-100 px-3 py-2`}>
                <span className={"d-flex flex-column justify-content-between"}>
                    <span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn mt-1"}
                        style={{width: "1.5rem", height: "1.5rem"}}>
                        {CpLangIco(langIsEn(asm.ALang))}
                    </span>
                    <span className={"d-flex justify-content-center align-items-start rounded fs-4 text-light mt-1"}
                        style={{width: "1.5rem", height: "1.5rem"}}>
                    </span>
                </span>
                <span className={"d-flex flex-column justify-content-between flex-grow-1"}>
                    <span className={"mb-2"}>
                        <div className={"semi-bold d-flex justify-content-between align-items-start fs-6 mt-1 mb-2"}><span>{AName}</span></div>
                        <div className={"d-flex justify-content-between align-items-end gap-3"}>
                    <span className={`d-flex flex-column gap-2`}>
                        <span className={`d-flex flex-wrap mt-2`}>{renderAssignToTag}</span>
                        <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                            <span className={"semi-bold pe-2"}>{t("assignment-info-start")}:</span>
                            <span>{AStartTime}</span>
                        </span>
                        <span className={"me-3 fs-8"}>
                            <span className={"semi-bold pe-2"}>{t("assignment-info-end")}:</span>
                            <span>{AEndTime}</span>
                        </span>
                        <span className={`d-flex`}><TagThrProgressTag AState={asm.AState} hasM={hasM}/></span>
                    </span>
                    <span className={`d-flex flex-column gap-3`}>
                        <span className={`d-flex flex-column align-items-center`}>
                            <span className={`semi-bold fs-8`}>{t("assignment-info-submission")}</span>
                            <span className={`d-flex align-items-end gap-2 semi-bold`}>
                            {hasM? <>
                                <span className={`fs-4 line-height-normal ${""? "" : "text-tag-success"}`}>{ WMarks }</span>
                                <span>/</span>
                                <span> { WTtl }</span>
                            </> : ''}
                            <span className={`fs-4 line-height-normal ${""? "": "text-tag-success"}`}>{ WSubmits }</span>
                            <span>/</span>
                            <span>{ WTtl }</span>
                            </span>
                        </span>
                        <DetailsCTA asm={asm}/>
                    </span>
                </div>
            </span>
        </span>
        </div>
    </div>
};
export default CpTeacherAssignmentCard;


export const GetTeacherActionButton = props => {
    const {asm, layout} = props;
    const s = asm?.AState;
    const pend = (s === _AST_PENDING);
    const aip = (s === _AST_IN_PROGRESS);
    const wresult = s === _AST_WAIT_RESULT;
    const LL = (layout === 'list');         //replaced "const LL = (layout === 'in-list');"
    const btnProps = props;
    
    // tobon 5 11 pass butnProps th all buttons
    return <span className={`d-none d-md-flex gap-2 ${LL? "flex-wrap" : "flex-wrap flex-lg-nowrap"} justify-content-end`}>
        {/*preJS({TAB_Layout: layout})*/}
        {aip? <CpEndAssignmentCTA {...btnProps} />: '' } 
        {pend? <CpChangeSettingsCTA {...btnProps}/>: ''}        {/*"change setting"*/}
        {aip? <CpChangeDeadlineCTA {...btnProps} />: ''}        {/*"change deadline"*/}
        {wresult? <CpPublishCTA {...btnProps} />: ''}
        { LL? <CpDetailsCTA {...btnProps} />: ''}
        {(LL && !pend)? <CpReportsCTA {...btnProps} />: ''}
        <CpDeleteCTA {...btnProps}/>
        <CpShareCTA  {...btnProps}/> 
    </span>;
}

export const GetTeacherButtonMore = props => {
    const {t, asmDo, reload, onRmAsm, asm, layout} = props;
    const s = asm?.AState;
    const pend = (s === _AST_PENDING);
    const aip = (s === _AST_IN_PROGRESS);
    const wresult = s === _AST_WAIT_RESULT;
    const LL = (layout === 'list');    

    const editOptions = Object.fromEntries([
        (aip) && ['endAsm', { t: t("schema:assignment-cta-end-assignment"), groupLast: "start" }],
        (pend) && ['editAsm', { t: t("schema:assignment-cta-change-settings"), groupLast: "setting" }],        
        (aip) && ['deadAsm', { t: t("schema:assignment-cta-change-deadline"), groupLast: "change" }],
        (wresult) && ['pubAsm', { t: t("schema:assignment-cta-publish-results"), groupLast: "pub"}],
        (LL && !pend) &&  ['rptAsm', { t: t("schema:assignment-cta-reports"), groupLast: "open" }],
        ['rmAsm', { t: t("schema:assignment-cta-delete"), groupLast: "rm" }],
        ['shareAsm', { t: t("schema:assignment-cta-share"), groupLast: "share" }],        
    ].filter(o => o));

    return <span className={`d-flex d-md-none gap-2`}>
{
        //preJS({TABM_Layout: layout})
}
        {LL && <CpDetailsCTA {...props}/>}
        <CpDropdown items={editOptions} cb={act => asmDo(asm, act, reload, onRmAsm)} 
            iconPath={IconList.general.moreVertical} className={"w100 d-block d-md-none more-dropdown"} grouping={true}/>
    </span>;
};

const CpDetailsCTA = ReduxBind(props => { //base on poc DetailsCTA
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {dispatch, asm, ...btnProps} = props;
    const clickAsmDetail = clickUrl(dispatch, _pathAssign(asm.assignId));
    return <CpActionButton title={t("assignment-cta-details")} iconPath={IconList.assignment.details}
        className={`CpDetailsCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmDetail} />
});

const CpReportsCTA = ReduxBind(props => { //base on poc ReportsCTA
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const {dispatch, asm, } = props
    const clickAsmReport = clickUrl(dispatch, _pathAssign(asm.assignId, 'report'));
    return <CpActionButton title={t("assignment-cta-reports")} iconPath={IconList.assignment.reports}
        className={`CpReportsCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={clickAsmReport} >
    </CpActionButton>
});

