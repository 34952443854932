import { useEffect, useState, useMemo} from 'react';

import { ReduxBind } from '../saga/ReduxState';

import { apiCallLoad, apiCallLoad_, } from '../libs/awsFuncs';
import { objKeys, strCmp, toAry, toObj, toStr } from '../libs/libType';
import * as UI from '../libs/libUI';
import { aTUITime, clickConfirm, clickUrl, dpDisplay, langDisplay, mergePubsDrafts, preJS } from './AppUtil';

import { _ATRoot } from '../consts/ATConstReact';
import { _ATMetaTypeByCode } from '../consts/ATMetaTypes';
import { ATDo_Meta, _ATCanDo } from '../consts/ATRoleRights';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { useTickAll } from './ATQtnEdit/PageATQtns';
import { cpATIcoBtn_, } from './components/CpATIcoBtn';

const selColor = "#0C8CE9";

const toDo = UI.stopEventThen( e => alert('TBC') );

const PageATSubRemes = ReduxBind((props) => { 
	const dispatch = props.dispatch;
	const role = toStr(props?._saga?.auth?.ATUser?.ATRole);

	const canMeta = _ATCanDo(role, ATDo_Meta) || undefined; 

	const [sSets, setSSets] = useState([]);
	const [metas, setMetas] = useState({});
	
	const smids =  useMemo(() => toAry(sSets).map(s => s.SMId), [sSets]);

	const [ticks, setTick, setTicks, tickAll, setTickAll, clearTicks] = useTickAll(smids);
	const cntTick = ticks?.length || 0;
	const tick1 = cntTick === 1;

	useEffect(() => {canMeta? reload(): dispatch(urlPush_Replace(_ATRoot)); }, []);
	
	const onLoadSSets = (res, err) => {
		const {metas} = toObj(res);
		const SSets = toAry(res?.SSets).sort((a, b) => b.dateMod - a.dateMod);
		setSSets(SSets);
		setMetas(ms => ({...ms, ...toObj(metas)}));
	};
	const reload = async () => {
		apiCallLoad(dispatch, '/getSubjSettings', onLoadSSets,{});
		clearTicks();
	};
	const clickNew = canMeta && clickUrl(dispatch, _ATRoot + 'subjectfollowup/new');
	const clickEdit = canMeta && clickUrl(dispatch, _ATRoot + 'subjectfollowup/edit/'+ticks[0]);
	const clickDelele = canMeta && clickConfirm(dispatch, 'Please confirm delete', () => apiCallLoad_( dispatch, '/deleteSubjSettings', onLoadSSets, {SMIds: ticks}) );
	const clickReload = e => { UI.stopEvent(e); reload(); };

	return <div className="PageATSubRemes adminAccountTop">
		<div className="flexColStart w100 p8" style={{height:'100%'}}>
		<div className='w100' style={{overflow:'auto', marginBottom:'50px'}}> 
		<div className="f16">Admin / Subject Follow-up Setting</div>
		<div className="adminToolsContainer"><div style={{display:"flex"}}>
			<div className="adminTools1">
				{canMeta && cpATIcoBtn_('labelDiagram/add', 'New', clickNew)}
				{canMeta && cpATIcoBtn_('general/edit', 'Edit', (tick1) && clickEdit)}
				{canMeta && cpATIcoBtn_('general/delete', 'Delete', (cntTick) && clickDelele)}
				{cpATIcoBtn_('general/reload', 'Reload', clickReload)}
			</div>
			<div className="adminTools2" onClick={toDo} style={{color:selColor}}></div>
		</div></div>
			<div className="metaItemRowHeader f14">
			<div className="sjstItem1">{UI.CheckBox0(tickAll, setTickAll, 'chkall')} All</div>
			<div className="sjstItem2">Subject metadata ID</div>
			<div className="sjstItem3">Subject</div>
			<div className="sjstItem4">No. of Questions</div>
			<div className="sjstItem5">Last Modified</div>
		</div>            
		<div className="SjStList metaListContainer">
			{ sSets.map((sSet, idx) => (sSetItem(idx, sSet, metas, ticks, setTick ))) }             
		</div>		
		{preJS(smids, 3)}
		{preJS(ticks, 3)}
		{preJS(sSets, 3)}
		{preJS(metas, 3)}
	</div>
	</div></div>
});
export default PageATSubRemes;

const sSetItem = (idx, sSet, metas, ticks, setTick ) => {
	//console.log('sSetItem', {idx, sSet, ticks, });
	const s = toObj(sSet);
	const smid = toStr(s.SMId);//m.metasetId + ',' + m.metaId;
	const m = toObj(metas?.[smid]);
	const on = ticks?.includes(smid);
	const _key = smid + '_'+ idx + '_' + (on? 'Y': 'N');
    //const subjDDI = useMemo(() => ent2DropdownItem(toAry(_subjMetas).map(j => [j.metaSetId+','+j.metaId, ut(j.nameEn, j.nameCt)])), [_subjMetas, uiEn]);  

	return <label className={'sSetItem'+_key+'_'+idx} key={'meta_'+_key} style={{width:'100%'}}>
		<div className="itemRowBlk"><div className="adminItemRow">
			<div className="sjstItem1">{UI.CheckBox0(on, setTick(smid), 'chk'+_key)}</div>
			<div className="sjstItem2 f15">{m.metaId}</div>
			<div className="sjstItem3 f15"><div>{m.nameEn}</div><div>{m.nameCt}</div></div>
			<div className="sjstItem4 f15"> {s.numQ}</div>
			<div className="sjstItem5 f15">
				<div>{aTUITime(s.dateMod)}</div><div>{s.modLogin}</div>
			</div>
	    </div></div>
	</label>;
};


