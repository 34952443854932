import { useEffect, useMemo, useState } from 'react';
import * as UI from '../../libs/libUI';
import { aTErrDiv1, aTErrTxt1, ATHr, ATUILabel, aTUIMust, preJS } from '../AppUtil';
import { _ATSysQTypeGroup, __SYSQSubType_FIB, __SYSQSubType_LBD, __SYSQSubType_LBT, __SYSQSubType_MCS, __SYSQSubType_MCT, __SYSQSubType_OED, __SYSQSubType_OEE, __SYSQSubType_OEG, __SYSQSubType_POL, expf2 } from '../../consts/ATSysQType';
import { toUniIdAry } from '../../consts/ATValidate';
import { exerQIds, exerUniQIds, normalizedEditExer } from '../../consts/ATValidateExer';
import { qAutoMark } from '../../consts/ATValidateQ';
import { asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { objEntries, objKeys, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import { ATUIText } from '../AppUtil';
import CpATSubjectTickList, { aTTickItem } from '../components/CpATSubjectTickList';
import { CpMetaLines } from '../components/CpMetaLines';
import { CpMetaTickList, mSetSplitMust } from '../components/CpMetaTickList';
import { loadOptsDrafMSet } from '../ATQtnEdit/PageATQtnEdit';
import { useUILang } from '../utils/useUILang';
import { _ATMetaType_SU } from '../../consts/ATMetaTypes';

const TabExerSetting = ReduxBind(props => {
    const {fields, setField, setTick, setESubjId, setDisplay, qtns, useCacheQtns, setOpts, lock, showPub, showEn, dispatch} = props;
    const fieldErrs = toObj(props.fieldErrs);

    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const ex = toObj(props.ex); 
    const {ELangEn, ELangCt} = ex;
    
    const opts = toObj(props.opts);
    const ATSubjects = toAry(opts.ATSubjects);
    const mySjIds = toAry(opts.mySjIds);
    
    const MSRows = toObj(showPub? opts.pubMSetRows: opts.draftMSetRows);
    const [MSMusts, MSOpts] = mSetSplitMust(MSRows);
    const [QMSMusts, QMSOpts] = mSetSplitMust(showPub? opts.pubQMRows: opts.draftQMRows);
    const [QMSPreMusts, QMSPreOpts] = mSetSplitMust(showPub? opts.pubQMPreRows: opts.draftQMPreRows); //expf2
    const [QMSProMusts, QMSProOpts] = mSetSplitMust(showPub? opts.pubQMProRows: opts.draftQMProRows); //expf2
    
    const uniQIds = useMemo(() => exerUniQIds(ex), [props.ex]);
    useCacheQtns(uniQIds);
    const EQSum = useMemo(() => mkEQSumFake(ex, qtns, ), [props.ex, qtns]);

    const clickLoadM = (!lock) && (async e => { UI.stopEvent(e); 
        const EMetaIDStr = normalizedEditExer(fields, opts).EMetaIDStr;
        setField('EMetaIDStr')(EMetaIDStr); 
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getATQtnMetas', { QMetaIDStr: EMetaIDStr, pubOnly:0 }) 
        setOpts(opts => ({ ...opts, ...loadOptsDrafMSet(res?.draftMetaSets)}));
    });

    //mssu1, KIN_ECE_K1;mssu1, KIN_ECE_N;mssu1, PRI_KS1;mssu2, a_a_a;mssu2, a_b_a;mssu2, b_a;mssu2, b_b;ms1, PRI_KS1_JunPri_P1;ms2, NCMATH2E_4B_7_7.2_A;ms1, PRI_KS1;ms1, NCMATH2E_4B_7_7.2_A;ms2, NCMATH2E_4B_7;ms2, NCMATH2E
    const draftSUMSets = opts?.draftMSetRows?.[_ATMetaType_SU];
    const SUSMIds = useMemo( () => toUniIdAry([].concat(...toAry(draftSUMSets).map(mset => objKeys(mset.rows).map(mid => mset.metaSetId+','+mid)))), [draftSUMSets]);
    useEffect( () => { if(!SUSMIds.includes(fields?.EMSubjId)) setField('EMSubjId')(toStr(SUSMIds[0])); }, [fields?.EMSubjId, SUSMIds]);

    const [EMSubjId, setEMSubjId] = [toStr(ex.EMSubjId), setField('EMSubjId')]; 
    const EMetaDisplays = toAry(ex.EMetaDisplays);
    const [escore, qids,  AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs] = toAry(EQSum); 
    return <>
    <div className='TabExerSetting atset'>
        <div className="atsethead" >General {aTUIMust}</div>
        <div className='flexRowStart'>{ATUILabel('Exercise ID','exid',true,110)}{ATUIText(ex.EId, setField('EId'), 'tqs', lock||(!(fields.isNew)))} </div>
        {aTErrTxt1(fieldErrs.EId, t)}
        {ELangEn? <div className='flexRowStart'>{ATUILabel('Exercise Title','extitle',true,110)}{ATUIText(ex.ENameEn, setField('ENameEn'), 'tes', lock)} </div>:''}
        {aTErrTxt1(fieldErrs.ENameEn, t)}
        {ELangCt? <div className='flexRowStart'>{ATUILabel('練習名稱','exctitle',true,110)}{ATUIText(ex.ENameCt, setField('ENameCt'), 'tes', lock)} </div>:''}
        {aTErrTxt1(fieldErrs.ENameCt, t)}
    </div>
    <ATHr/>
    <div className='atset'>
        <div className='atfieldhead'>Enable for Subject(s) in AT {aTUIMust}</div>
        <div className='subjectItemContainer f14'>
            {CpATSubjectTickList( ATSubjects, mySjIds, toAry(ex.ESubjIds), setESubjId, lock) }
            {aTErrTxt1(fieldErrs.ESubjIds, t)}
        </div>
    </div>
    <div className='atset'>
        <div className='atfieldhead'>Enable for exercise mode(s) {aTUIMust}</div>
        <div className='flexRowStart f14'>
            {aTTickItem(ex.EModeScroll,  setTick('EModeScroll'), ' Question on Same Page (code:scroll) ', lock )}
            {aTTickItem(ex.EModeSlide,  setTick('EModeSlide'), ' One question per page (code:slide) ', lock )}
            {showPub? '': aTErrDiv1(fieldErrs?.EMode, t)}
        </div>
    </div>
    <ATHr/>
    <div className="atsect">
        <div className='atsecthead'>Exercise Metadata {aTUIMust}</div>
        <div className="atrow">
            <div className="atfieldhead">Metadata Codes(s) {aTUIMust}</div>
            {ATUIText(toStr(ex.EMetaIDStr), setField('EMetaIDStr'), 'txtMC', lock)}
            <div>{lock? '': UI.Button0('reload', clickLoadM, 'btnlm')}</div>
        </div>
        {aTErrTxt1(fieldErrs?.EMetaIDStr, t)}
    </div>
    <div className="atsect">
        {preJS({EMSubjId, EMetaDisplays})}
        <CpMetaTickList {...{lock, t2, en:showEn, MSMusts, MSOpts, tickLbls:['Display'], tickss:[EMetaDisplays], setterss:[setDisplay], EMSubjId, setEMSubjId}} />
    </div>
    <ATHr/>

    <div className='atsect'>
        <div className='atsecthead'>Metadata from included questions(s) {aTUIMust}
            <div className="atrowmin"><div className='atfieldhead'>Total Questions: {toAry(qids).length}</div></div>
            <div className="atrowmin">
                <div className='atfieldhead'>Auto-marked: </div><CpEQSum {...{cnt:AQCnt, typeQs:ATypeQs, groupQs:ATGroupQs}} />
            </div><div className="atrowmin">
                <div className='atfieldhead'>Manually marked: </div><CpEQSum {...{cnt:MQCnt, typeQs:MTypeQs, groupQs:MTGroupQs}} />
            </div>
            <div className="atrowmin"><div className='atfieldhead'>Total Marks: {escore} </div></div>
        </div>
    </div>
    <ATHr/>
    <div className="atsect">
        <div className='atsecthead'>Metadata from included questions(s) {aTUIMust}</div>
    </div><div>
        <CpMetaLines  {...{en:showEn, MSMusts:QMSMusts, MSOpts:QMSOpts,}} />
    </div>
    {expf2?<>
        <div className="atsect">
            <div className='atsecthead'>Prior knowledge</div>
        </div><div>
            <CpMetaLines  {...{en:showEn, MSMusts:QMSPreMusts, MSOpts:QMSPreOpts,}} />
        </div><div className="atsect">
            <div className='atsecthead'>Advanced knowledge</div>
        </div><div>
            <CpMetaLines  {...{en:showEn, MSMusts:QMSProMusts, MSOpts:QMSProOpts,}} />
        </div>
    </>:''}
    <ATHr/>
    <div> Keyword(s): {ATUIText(ex.EKwEn, setField('EKwEn'), 'tke', lock, 'use "," as separator')} </div>
    <div> 關鍵字: {ATUIText(ex.EKwCt, setField('EKwCt'), 'tke', lock, 'use "," as separator')} </div>
    </>;
});
export default TabExerSetting; 

export const mkEQSumFake = (ex, qtns) => {
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = mkEQSum(ex, qtns);
    return [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, 
    toUniIdAry([...EQMetas, ]),//"ms2,NCMATH2E", "ms2,NCMATH2E_4B", "ms2,NCMATH2E_4B_7", "ms1,PRI", "ms1,PRI_KS1", 'mstb1,a', 'mstb1,a_a', 'mstb1,b' ]),
    toUniIdAry([...EQMetaPres, ]),//"ms2,NCMATH2E_4A_3_3.1","ms1,PRI_KS1_JunPri", "ms1,PRI_KS1_JunPri_P1" ]),
    toUniIdAry([...EQMetaPros, ]),//"ms1,PRI_KS1", "ms2,NCMATH2E_4B_7", "ms2,NCMATH2E_4A_1_1.1_A", "ms2,NCMATH2E_4A_1_1.2_A", "ms2,NCMATH2E_4B_7_7.2", "ms2,NCMATH2E_4B_7_7.2_A"]),
    minQs    
  ];
};

export const mkEQSum = (ex, qtns) => {
    const eqids = exerQIds(ex);
    const eqtns = qtns; 
    
    var [qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore] = [[], 0, 0, {}, {}, {}, {}, 0 ,0];
    const minQs = [];
    eqids.forEach(qid => {
        const q = toObj(eqtns[qid]);
        const t = q.SQType;
        const g = _ATSysQTypeGroup(t);
        if(!(t && g)) 
            return;
        const s = toInt(q.score);
        
        if(qAutoMark(q)){
            ATypeQs[t] = 1+toInt(ATypeQs[t])
            ATGroupQs[g] = 1+toInt(ATGroupQs[g]);
            AQCnt++;
            AScore+=s;
        }else{
            MTypeQs[t] = 1+toInt(MTypeQs[t]);
            MTGroupQs[g] = 1+toInt(MTGroupQs[g]);
            MQCnt++;
            MScore+=s;
        }
        qids.push(qid);
        const {QMetaIDStr, QMetaPreIDStr, QMetaProIDStr, QDisplayMetas, QMetaPres, QMetaPros} = q;
        minQs.push({qid, QMetaIDStr, QMetaPreIDStr, QMetaProIDStr, QDisplayMetas, QMetaPres, QMetaPros});
    });
    const escore = AScore + MScore;
    const EQMetas = toUniIdAry([].concat(...minQs.map(q => toAry(q.QDisplayMetas))));
    const EQMetaPres = toUniIdAry([].concat(...minQs.map(q => toAry(q.QMetaPres))));
    const EQMetaPros = toUniIdAry([].concat(...minQs.map(q => toAry(q.QMetaPros))));

    return [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ];
};

export const CpEQSum = props => {
    const {cnt, typeQs } = props;
    return <div className="w" >
        <div className="w p4" >{[[cnt]]}</div>
        <div className="w" >{objEntries(typeQs).map(([t, c]) => {
            return <div className="w p4" key={t}>[{sqtname(t)}: {c}] </div>
        })}</div>
    </div>;
};

const SYSQSTypesName = {
    [__SYSQSubType_MCS]: 'MC',
    [__SYSQSubType_MCT]: 'MC-table',
    [__SYSQSubType_FIB]: 'FIB',
    [__SYSQSubType_LBT]: 'Label the Diagram',
    [__SYSQSubType_LBD]: 'Label the Diagram-Dropdown',
    [__SYSQSubType_OED]: 'Drawing',
    [__SYSQSubType_OEG]: 'Open-end',
    [__SYSQSubType_OEE]: 'Essay',
    [__SYSQSubType_OED]: 'Drawing',
    [__SYSQSubType_POL]: 'Polling', 
};
const sqtname = t => (SYSQSTypesName[t] || t);