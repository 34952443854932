import {useEffect, useRef, useState} from "react";
import {
    DONUT_DEFAULT_SEGMENT_COLOR,
    getChartColor,
    getProgressStatusColor,
    getSchoolMonthIdByValue,
    LineBarType,
    LineBarValueType,
} from "../../EPReport/ChartTypeConst";

import ChartLineBar from "./ChartLineBar";
import CpLineBarIndicator from "./CpLineBarIndicator";
import { getUUID } from "../../_components/CpGetUUID";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { aryLen, isAry, toAry, toFix1, toObj, toStr } from "../../../libs/libType";
import { getTransMap } from "../../../libs/libTransMap";

const ChartProgressByMonth = (props) => { // base on poc ProgressByMonthChart
    //const [isThr, isStt, uRole ] = expfRoles(props);
    const [ t ] = useUILang();
    const chartRef = useRef();

    const isStt = props?.isStt;
    const {items = [], lineBarType, type} = props;
    const data = toAry(props.data);
    const drawLine = (lineBarType === LineBarType.LINE);

    const { isKeyStage, rptKeyStage } = props;
	const {KSYears, KSClss, KSStus} = toObj(rptKeyStage);

    const [DCF, setDCF] = useState(0);
    useEffect(() => { setDCF(DCF? 0: 1); },[data]);

    const [selectedIndicators, setSelectedIndicators] = useState(items.map(item => item.id));
    useEffect(() => { if (isAry(items)) setSelectedIndicators(items.map(item => item.id)); }, [items]);
    

    const rpGpLbl = rg => isKeyStage? clsInf2SchYr(KSClss, rg): getSchoolMonthIdByValue(rg, t); 
    const renderChartData = () => {
        const u_id = getUUID();
        const lineData = data.map( item => {
            const {referenceId, rpGroup, progress, averageScore, overview} = item;
            const avgP = toFix1(averageScore.percentage);
            const lineId = referenceId || u_id;
            const lineTitle = `${avgP}`;
            const lineReferenceId = rpGpLbl(rpGroup); 
            const lineValue = avgP;
            const colorIndex = items.findIndex(item => item.id === referenceId);
            return {
                id: lineId,
                title: lineTitle,
                referenceId: lineReferenceId,
                value: lineValue,
                color: (type === "a" || type === "sc")? getChartColor(colorIndex): DONUT_DEFAULT_SEGMENT_COLOR
            }
        });
        const barData = data.map( item => {
            const {referenceId, rpGroup, progress, averageScore, overview} = item;
            const barId = referenceId || u_id;
            const barTitle = `${toFix1(averageScore.percentage)}`;
            const barReferenceId = rpGpLbl(rpGroup); 
            const colorIndex = items.findIndex(item => item.id === referenceId);
            switch (type) {
                case "a":
                case "sc":
                    return {
                        id: barId,
                        title: barTitle,
                        referenceId: barReferenceId,
                        value: progress?.value || 0,
                        color: getChartColor(colorIndex)
                    };
                case "s":
                    return overview?.map(ov => {
                        return {
                            id: barId,
                            title: barTitle,
                            referenceId: barReferenceId,
                            value: ov.value,
                            color: getProgressStatusColor(ov.status)
                        }
                    }) || [];
                default:
                    return {
                        id: "",
                        title: "",
                        referenceId: "",
                        value: 0,
                        color: ""
                    }
            }
        }).flat();
        return { line: lineData, bar: barData };
    };

    const selectIndicator = e => {
        const key = "group-item-data";
        const target = e.currentTarget;
        if (target) {
            const value = target.getAttribute(key);
            if (value && chartRef.current) {
                const currentSelectedIndicators = updateArrayValue([...selectedIndicators], value);
                setSelectedIndicators(currentSelectedIndicators);
                chartRef.current.handleShowData({key, value: currentSelectedIndicators});
            };
        };
    };

    const id = toStr(props.id);
    const className = toStr(props.className);
    return <>{aryLen(items)? <CpLineBarIndicator xItems={items} onClick={selectIndicator}/>: ""}
        <div id={id} className={'ChartProgressByMonth '+className+' progress-by-month-container'}>
            <div className={"overflow-hidden w-100 h-100 "}>
                {/*<div className={"max-size-chart-500 text-center"}>*/}
                <div className={"max-size-chart-400 text-center"}>
                    <ChartLineBar key={lineBarType+""+DCF} {...{lineBarType, isKeyStage, rptKeyStage,
                        title: t(drawLine? "report-average-score-percentage": getTransMap("report-no-of-assignment", isStt)),
                        lineBarValueType: (drawLine? LineBarValueType.PERCENTAGE: LineBarValueType.VALUE),
                        data: renderChartData(), ref: chartRef}} />
                </div>
            </div>
        </div>
    </>
};
/*
                    <ChartLineBar key={forceRenew()} {...{lineBarType, isKeyStage, rptKeyStage,
                        title: t(drawLine? "report-average-score-percentage": getTransMap("report-no-of-assignment", isStt)),
                        lineBarValueType: (drawLine? LineBarValueType.PERCENTAGE: LineBarValueType.VALUE),
                        data: renderChartData(), ref: chartRef}} />
*/

export default ChartProgressByMonth;

const updateArrayValue = (array, value) => { // base on poc updateArrayValue.ts
    const index = array.indexOf(value);
    if (index !== -1) {
        array.splice(index, 1);        // Value exists in the array, so remove it
    } else {
        array.push(value);        // Value doesn't exist in the array, so add it
    };
    //console.log({array});
    return array;
};

const clsInf2SchYr = (cInfs, cId) => {
    //const c = toAry(cInfs).find(i => (i.clsId === cId));
    const c = cInfs?.[cId];
    return toStr(c?.year || cId);
};