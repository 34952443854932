import React, { useEffect, useState, useRef } from 'react';
import * as UI from '../../libs/libUI';
import { iconButton } from '../components/CpATIcoBtn';
import DrawingCanvas, { delCavObj, cavHasSelected } from "../components/CpDrawingCanvas";
import { ChromePicker } from 'react-color';
import { downloadUrl } from '../../libs/libDownload';
import { useUILang } from '../utils/useUILang';
import { popConfirm } from '../components/CpPopup';
import { toStr, toAry, toObj, txtByteSize } from '../../libs/libType';
import { useFabric } from '../components/CpDrawingCanvas';
import { drawUndoRedo, newDrawBar } from '../../consts/ATSysQType';
import { IconList } from '../../consts/ATIconListToUsePoc';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import CpIco from '../../AppExPFUser/_components/CpIco';
import { newDrawFunc } from '../../consts/ATSysQType';
import { s3toImgSrc } from '../utils/useMediaCache';
import { fabricDebonce } from '../utils/useAutoSave';
import { validDrawSize, byteToMB } from '../utils/utilQtnAns';
import { popAlert } from '../components/CpPopup';
import { isMobileScreen } from '../../poc/helper/getWidnowWidth';

const FabricHistoryAction = { UNDO: "undo", REDO: "redo", LOG: "log", CLEAR: "clear" }

const fileTypes = ['jpg', 'jpeg', 'png'];
const brushIcons = { 4: 'tiny', 6: 'small', 10: 'medium', 14: 'big', 18: 'huge' };
const getBrushIcon = s => 'draw/' + brushIcons[s];
const ctSizeMobile = 32;
const ctSizeDesktop = 24;

const sizes = [4, 6, 10, 14, 18];
const getSizeName = bs => <CpIco src={
    bs === sizes[1] ? IconList.draw.small
        : bs === sizes[2] ? IconList.draw.medium
            : bs === sizes[3] ? IconList.draw.big
                : bs === sizes[4] ? IconList.draw.huge
                    : IconList.draw.tiny} />

const getTextSize = (ts) => (ts === sizes[4]) ? 36
    : (ts === sizes[3]) ? 32
        : (ts === sizes[2]) ? 28
            : (ts === sizes[1]) ? 24
                : 20;

const getRGBA = (rgba) => { //color.rgb
    const { r = 0, g = 0, b = 0, a = 1 } = rgba;
    return `rgba(${r},${g},${b},${a})`
}
const initBColor = { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1, }, hsl: { h: 240, s: 0, l: 0, a: 1 } };
const initHColor = { hex: 'rgba(231,220,94,0.55)', rgb: { r: 231, g: 220, b: 94, a: 0.55, }, hsl: { h: 90, s: 50, l: 13, a: 55 } };

const DrawComponentV5 = props => {
    const { dispatch, bgData0, doReset = 0, setDoReset, setResponse, preview, showResult, doEdit, isTeacher = 0,
        drawData, bgData, ignoreRemoveBG = 0, //draw json / bg json pass down
        idx, setParentCanvas, enableCompress = 1, editQtn = 0, BGImgID, BGImgSrc, LDMode = 'ftw', bgBtn = 1, isAT, DLBtn = 1,
        setOnAddMedia, mediaDLs, getMediaDLs, doDisable = 0, setDoDisable, needDrawDeb=0,
        setOverBarReady = 0, gotoQ = 0, QNo, isOverLay = 0, setVisDrawLayer = 0, PVMode } = props;

    const [t, showEn] = useUILang();

    const [canvas, setCanvas] = useState(null);
    const isScriptReady = useFabric();

    const [clonedDraw0, setClonedDraw0] = useState(undefined); // qtn draw
    const [clonedDraw, setClonedDraw] = useState(undefined); // teacher or student draw
    const [bcolor, setBcolor] = useState(initBColor);
    const [hcolor, setHcolor] = useState(initHColor);
    const [brushSize, setBrushSize] = useState(sizes[0]);
    const [hbrushSize, setHbrushSize] = useState(sizes[3]);
    const [showColor, setShowColor] = useState(false);
    const [showBrush, setShowBrush] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [selected, setSelected] = useState(false);
    const [drawReady, setDrawReady] = useState(false);
    const [mode, setMode] = useState(isOverLay ? 'b' : 's'); // s select, b brush, e eraser, h highlight
    const [hasBG, setHasBG] = useState(props?.hasBG || 0); // inside canvas bg
    const [BGInfo, setBGInfo] = useState({ loaded: false, width: 0, height: 0 });  // qtn bg
    const [BGData, setBGData] = useState(null);
    const myLastImgSrc = useRef('');
    const colorPickerRef = useRef(null);
    const [selectedTool, setSelectedTool] = useState(isOverLay ? "pencil" : "select");
    const [doUndo, setDoUndo] = useState(0);

    const [pencilType, setPencilType] = useState("pencil"); //draw control
    const [lineType, setLineType] = useState("straightLine"); //draw control
    const [shapeType, setShapeType] = useState("circle");
    const [dataTimer, setDataTimer] = useState();
    
    const imageInputRef = useRef(null);
    const backgroundInputRef = useRef(null);

    const toolContainer = useRef(null);

    const _newDrawFunc = newDrawFunc; // && preview;
    const _ctPointSize = isMobileScreen() ? ctSizeMobile:ctSizeDesktop;

    const fabricHistoryRef = useRef({
        maxHistorySteps: -20,
        historyLock: false,
        undo_history: [],
        redo_history: []
    })

    const [gVar, no_use_setGVar] = useState({
        activeLine: null,
        activeShape: null,
        lineArray: [],
        pointArray: [],
        drawMode: false,
        bcolor: '#000000',
        bsize: 4,
        act: "",
        fromAct: "",
        drawReady: drawData?1:0,
        dirty: 0,
        mDown: 0,
    });

    const id = 'drawComp' + (doEdit ? 'edit' : 'read') + (idx || 0);// +uuidv4();
    const drawBarID = 'drawBar' + (idx || 0);
    // qtnBG(BGInfo) -> bgData0 -> bgData -> drawData
    // qtnBG -> bgData0 -> clonedDraw2 -> bgData -> clonedDraw -> drawData
    const newTimer = nt => setDataTimer(ot => {
        if (ot) { clearTimeout(ot); } 
        return nt;
    });
    useEffect(() => { return () => {
        newTimer(); 
    }}, []); // clear Timer, 

    useEffect(()=> { if (doUndo) {
        handleUndo();
        setDoUndo(0);
        const rh = fabricHistoryRef.current.redo_history;
        if (rh && rh.length > 0) rh.length = rh.length - 1;
    }}, [doUndo]);

    const finishPoly = () => {
        if (gVar.drawMode) {
            if (gVar.pointArray.length > 0) {
                //console.log('finish - generate poly');
                generatePolygon(gVar.pointArray, 0);
            } else {
                drawPolyMode(false);
            };
        };
    };

    const beforeHandle = (act="", fromAct="") => {
        if (gVar.drawMode) finishPoly();
    };

    const _updateData = (cv, caller, updateFor) => {
        //if (gVar.drawMode) finishPoly(); // ensure no invalid polygon data
        //console.log("_updateData");
        if (cv && !(doInit.current) && !gVar.drawMode && gVar.dirty) { // need to check again for autosave
            //console.log("valid draw response !!");
            gVar.dirty = 0;
            const re = getCVData(cv);
            //console.log(re);
            setResponse(re); //only call when user update draw
            setSelected(cavHasSelected(cv));
        } else {
            //console.log("invalid draw response XXXXX");
        };
    };

    const debounce = (cv, caller) => newTimer(setTimeout( () => { 
            //console.log("------- time up debounce update data")
            _updateData(cv, caller, 'debounce');
    }, fabricDebonce ));

    const isValidSize = (cv) => {
        //const [isValid, drawSize] = (!preview && canvas)? validDrawSize(canvas, isTeacher?'te':'st'): [1,0];
        const [isValid, drawSize] = (cv)? validDrawSize(cv, isTeacher?'te':'st'): [1,0];
        return {isValid, drawSize};
    };

    //Output     
    const _updateResp = (cv, opt, caller) => { //internal Return Draw Data
        //console.log("gVar.drawReady", gVar.drawReady);
        /*console.log("canvas response", {
            canvasReady:cv?1:0,
            drawReady: gVar.drawReady,
            notInit: !doInit.current?1:0,
            notDraw: !gVar.drawMode?1:0,
            notHistory: !fabricHistoryRef.current.historyLock?1:0});*/
        if (cv && gVar.drawReady && !(doInit.current) && !gVar.drawMode && !fabricHistoryRef.current.historyLock) {
            //console.log("update 1 _updateResp");
            //_updateData(cv);
            const check = isValidSize(cv);
            if (check.isValid) {
                //console.log("valid data, update resp");
                gVar.dirty = 1;
                if (needDrawDeb) { 
                    //console.log("------- draw updated, do debounce");
                    debounce(cv, caller);
                } else _updateData(cv);
            } else {
                popAlert(dispatch, 1, t(isTeacher? 'warning.warning_drawing_total_size_teacher'
                : 'warning.warning_drawing_total_size_student').replace("%s", byteToMB(check.drawSize)),
                ()=>{ setDoUndo(1); });
            };
        };
    };
    const updateResp = (cv, opt) => _updateResp(cv, opt, 'Drawing_Canvas_Resp'); //callback for DrawingCanvas 

    useEffect(() => {
        if (isOverLay) {
            handlePen('pencil');
        };
        //setOverBarReady && setOverBarReady(1);
        return () => {
            //setOverBarReady && setOverBarReady(0);
        };
    }, [canvas]);

    useEffect(() => {
        if ((myLastImgSrc.current !== '') && (BGImgSrc !== myLastImgSrc.current)) {
            if (canvas) {
                canvas.dispose();
                setCanvas(null);
            };
            setBGInfo({ loaded: false, width: 0, height: 0 });
            setBGData(null);
        };
        myLastImgSrc.current = BGImgSrc;
    }, [BGImgSrc]);

    const calculateQtnImage = img => {
        const ar = (LDMode === 'ftw')
            ? canvas.width / img.width
            : canvas.height / img.height;
        return { finalAspectRatio: ar };
    };

    //const getArrowSize = () => { return gVar.bsize * 2 };
    const isPen = () => { return ["pencil", "highlight", "eraser"].includes(selectedTool); };

    useEffect(() => { if (canvas && (mode === 's')) { canvas.isDrawingMode = false; }}, [mode]); //change mode
    useEffect(() => { if (canvas && canvas.freeDrawingBrush) canvas.freeDrawingBrush.width = brushSize; }, [brushSize]); // change Brush size

    useEffect(() => {
        if (canvas) {
            if (isPen()) {
                canvas.selection = false;
                setupBrush(selectedTool);
                changeCursor();
            } else { canvas.selection = true; };
        };
        /*if (["pencil", "highlight", "eraser"].includes(selectedTool)) {
            setupBrush(selectedTool);
            changeCursor();
        };*/
    }, [selectedTool, bcolor, hcolor, brushSize, hbrushSize]);

    useEffect(() => { // Update Select Object Color
        if (!canvas) return;
        gVar.bcolor = getRGBA(bcolor.rgb);
        gVar.bsize = brushSize;
        if (canvas.freeDrawingBrush) canvas.freeDrawingBrush.color = (gVar.bcolor || '#000000');
        canvas.getActiveObjects().forEach(obj => {
            if (obj.type === "i-text") {
                obj.set("fill", gVar.bcolor);
            } else if (["path", "polygon", "circle", "line", "text", "polyline", "arrowline"].includes(obj.type.toLowerCase())) {
                obj.set("stroke", gVar.bcolor);
                if (obj.fill && (obj.type !== "circle")) obj.set("fill", gVar.bcolor);
                obj.set("strokeWidth", gVar.bsize);
            };
        });
        canvas.renderAll();
        _updateResp(canvas);
    }, [bcolor, brushSize]);

    const doInit = useRef(true);
    const drawIsReady = () => {
        pasteObjects();
        setDrawReady(true);
        gVar.drawReady = 1;
        //handleHistory(FabricHistoryAction.LOG);
    };
    const loadBGDone = () => { copyDraw(setClonedDraw); };
    const loadData = () => {
        //console.log("load data !!!!!!!!!!!!!!!!!!!!!!!");
        if ((isTeacher || showResult) && !editQtn) {
            //console.log('bgData', bgData);
            if (bgData) canvas.loadFromJSON(bgData, loadBGDone);
        } else {
            if (drawData) { 
                //console.log("load drawData !!!");
                canvas.loadFromJSON(drawData, drawIsReady);
            } else drawIsReady();
            canvas.requestRenderAll();
            doInit.current = false;
        };
    };

    const loadBG0Done = () => { copyDraw(setClonedDraw0); };
    const resetDraw = () => {
        if (!canvas) return;
        doInit.current = true;
        gVar.drawReady = 0;
        if (bgData0) {
            canvas.loadFromJSON(bgData0, loadBG0Done);
        } else {
            loadData();
        };
    };

    useEffect(() => {
        if (doReset) { //reset on command
            resetDraw();
            setDoReset && setDoReset(0);
        };
    }, [doReset]);

    useEffect(() => { // canvas script Ready Effect
        if (isScriptReady) {
            //console.log('script ready !!');
            resetDraw();
            initDrawPolygon();
            initArrow();
            initCanvasEvent();
            initCanvasHistory();
            if (canvas && setParentCanvas) {
                setParentCanvas(canvas);
            };
        } else {
            //console.log('script NOT ready !!');
        };
    }, [canvas, isScriptReady]);

    useEffect(() => { if (drawReady) { checkIsBackgroundExist(); }}, [drawReady]);

    const LoadDrawDone = () => {
        pasteObjects();
        checkIsBackgroundExist();
        setDrawReady(true);
        gVar.drawReady = 1;
        doInit.current = false;
    };
    useEffect(() => {
        if ((isTeacher || showResult) && canvas) {
            if (drawData) canvas.loadFromJSON(drawData, LoadDrawDone)
            else LoadDrawDone();
        };
    }, [clonedDraw]);

    useEffect(() => { if (canvas) loadData(); }, [clonedDraw0]);

    const checkIsBackgroundExist = () => { if (canvas) setHasBG(canvas.backgroundImage ? 1 : 0); };

    const maxFileSize = 50000000;
    const isExceedFileSize = size => (size > maxFileSize);

    const getCVData = (cv) => {
        //let canvasContent = JSON.stringify(canvas); //direct json canvas object ?
        //let canvasContent = JSON.stringify(canvas.toJSON(['selectable', 'evented', 'oupInfo', 'transparentCorners', 'objectCaching']));
	    //const re = JSON.stringify(cv.toJSON(['selectable', 'evented', 'oupInfo', 'transparentCorners', 'objectCaching']));        
        return JSON.stringify(cv.toJSON(['selectable', 'evented', 'transparentCorners', 'objectCaching', 'crossOrigin']));
    };

    const cvOnFocus = (e) => { console.log("canvas on focus: ", id); };
    const cvOnBlur = (e) => { console.log("canvas on blur: ", id); };    

    const copyDraw = (cloneTo) => {
        if (!canvas) return;
        const sel = new window.fabric.ActiveSelection(canvas.getObjects(), { canvas });
        canvas.setActiveObject(sel);
        if (canvas.getActiveObject().type !== 'activeSelection') {
            return;
        };
        canvas.getActiveObject().toGroup();
        const gg = canvas.getActiveObject();
        copyObjects(cloneTo);
        gg.set({ evented: false, selectable: false, erasable: false, excludeFromExport: true });
        canvas.discardActiveObject();
        canvas.requestRenderAll();
    };

    const copyObjects = cloneTo => { if (canvas) canvas.getActiveObject().clone(cloned => cloneTo(cloned)); };

    const pasteObjects = () => {
        if (!canvas) return;
        if (clonedDraw) {
            //console.log('paste clonedDraw');
            clonedDraw.set({ evented: false, selectable: false, erasable: false, excludeFromExport: true });
            canvas.add(clonedDraw);
            clonedDraw.sendToBack();
        };
        if (clonedDraw0) {
            //console.log('paste clonedDraw0');
            clonedDraw0.set({ evented: false, selectable: false, erasable: false, excludeFromExport: true });
            canvas.add(clonedDraw0);
            clonedDraw0.sendToBack();
        };
        canvas.requestRenderAll();
    };

    const setupBrush = (brushType) => {
        if (canvas) {
            canvas.isDrawingMode = true;
            if (brushType === 'highlight') {
                canvas.freeDrawingBrush = new window.fabric.PencilBrush(canvas);
                canvas.freeDrawingBrush.color = getRGBA(hcolor.rgb); //hcolor.hex;
                canvas.freeDrawingBrush.width = hbrushSize;
                setMode('h');
            } else if (brushType === 'eraser') {
                canvas.freeDrawingBrush = new window.fabric.EraserBrush(canvas);
                canvas.freeDrawingBrush.color = getRGBA(bcolor.rgb); //bcolor.hex;
                canvas.freeDrawingBrush.width = brushSize;
                setMode('e');
            } else {
                canvas.freeDrawingBrush = new window.fabric.PencilBrush(canvas);
                canvas.freeDrawingBrush.color = getRGBA(bcolor.rgb); //bcolor.hex;
                canvas.freeDrawingBrush.width = brushSize;
                setMode('b');
            };
        };
    };

    const setBrushColor = (color, e) => {
        beforeHandle();
        UI.stopEvent(e);
        if (selectedTool === "highlight") {
            setHcolor(color);
        } else {
            setBcolor(color);
        };
    };

    const addText = () => {
        if (canvas) {
            canvas.isDrawingMode = false;
            setMode('s');
            canvas.add(new window.fabric.IText('text', {
                left: 50,
                top: 100,
                fontFamily: 'arial black',
                fill: getRGBA(bcolor.rgb),
                fontSize: getTextSize(brushSize),
                hasBorders: false
            }));
            canvas.renderAll();
        };
    };

    const undoCanvas = () => {
        //if (canvas) { canvas.undo() };
    };

    const redoCanvas = () => {
        //if (canvas) { canvas.redo() };
    };

    const handleImage = (style) => {
        beforeHandle();
        if (style === "image") {
            if (imageInputRef.current) {
                imageInputRef.current.click();
            }
        } else {
            if (backgroundInputRef.current) {
                backgroundInputRef.current.click();
            };
        };
    };

    const handleRemoveBG = () => {
        beforeHandle();
        if (canvas) {
            canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
            _updateResp(canvas, 'handleRemoveBG');
            setHasBG(0);
        };
    };

    const loadCanvasBG = () => {
        return new Promise(async (resolve, reject) => {
            if (BGImgSrc || BGImgID) {
                /*
                const image = new Image();
                image.crossorigin = "anonymous";
                image.onload = () => {
                    const spec = calculateQtnImage(image);
                    setBackgroundToCanvas(image, spec);
                    resolve();
                };
                image.src = BGImgSrc;
                */
                //console.log({BGImgID, BGImgSrc, canvas});
                let imgData = null;
                if (BGData) { imgData = BGData; }
                else { imgData = await s3toImgSrc(dispatch, BGImgID, getMediaDLs); }

                if (imgData) {
                    setBGData(imgData);
                    //console.log("imgData", {imgData});
                    const image = new Image();
                    image.crossorigin = "anonymous";                                    
                    image.onload = () => {
                        const spec = calculateQtnImage(image);
                        const img = new window.fabric.Image(image).scale(spec.finalAspectRatio);
                        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
                        canvas.backgroundImage.center();
                        canvas.backgroundImage.set('erasable', false);                    
                        canvas.renderAll();
                        resolve();
                    };
                    image.src = imgData;
                };

                /*
                const fabric = window.fabric;
                fabric.Image.fromURL(BGImgSrc,(img) => {
                    //const spec = calculateQtnImage(img);
                    //img.scale(spec.finalAspectRatio);
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
                    canvas.backgroundImage.center();
                    canvas.backgroundImage.set('erasable', false);                    
                    canvas.renderAll();
                    setMode('s');
                    resolve();
                    }, {crossOrigin: "anonymous"}
                );
                */

            } else { resolve(); };
        });
    };

    const resizeImageIfNeeded = (imageData) => {
        const maxAllowedWidth = 1920;
        const maxAllowedHeight = 1080;

        return new Promise((resolve, reject) => {
            try {
                const image = new Image();
                image.crossorigin = "anonymous";
                image.onload = function () {
                    const width = image.width;
                    const height = image.height;
                    let newWidth = width;
                    let newHeight = height;
                    //console.log("resize",{width, height});
                    if (width > maxAllowedWidth || height > maxAllowedHeight) {
                        const widthRatio = maxAllowedWidth / width;
                        const heightRatio = maxAllowedHeight / height;
                        const scaleFactor = Math.min(widthRatio, heightRatio);
                        newWidth = width * scaleFactor;
                        newHeight = height * scaleFactor

                        if (isExceedFileSize(newWidth * newHeight)) {
                            resolve('exceed_size');
                        };

                        const canvas = document.createElement("canvas");
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.drawImage(image, 0, 0, newWidth, newHeight);
                        };

                        // Get the resized image as a data URL
                        const resizedImageData = canvas.toDataURL("image/png", 1); // You can adjust the quality (0.0 - 1.0) as needed
                        resolve(enableCompress ? resizedImageData : imageData);
                    } else {
                        resolve(imageData);
                    }
                };
                image.onerror = error => {
                    console.log("Err resize load image:", error);
                    resolve("");
                };
                image.src = imageData;
            } catch (e) { 
                console.log("Err resize:", e);
                resolve("");
            };
        });
    };

    const doAddMediaImg = (e) => {
        UI.stopEvent(e);
        if (setOnAddMedia) {
            setOnAddMedia({
                onAdd: ( async medias => {
                    const ms = toAry(medias);
                    if (ms.length > 0) {
                        const src = toStr(ms[0].dl);
                        const mediaId = toStr(ms[0].mediaId);
                                    
                        const imgData = await s3toImgSrc(dispatch, mediaId, getMediaDLs);
                        if (imgData) {
                            //console.log("imgData", {imgData});
                            const resizedImage = await resizeImageIfNeeded(imgData);
                            if (resizedImage !== 'exceed_size') {
                                const image = new Image();
                                image.crossorigin = "anonymous";                                    
                                image.onload = function () {
                                    const spec = calculateImage(image);
                                    addImageToCanvas(image, spec);
                                };
                                image.src = resizedImage;
                            } else { showImageWarning(); };
                        };
                    };
                    setOnAddMedia(0); //stop receiveMedia
                }), maxMedia: 1, mimes: ['image']
            });
        };
    };

    const showImageWarning = () => {
        popConfirm(dispatch, 1, t('warning.warning_drawing_image_file'), () => { }, 0);
    };
    const handleUpload = (e, style) => {
        beforeHandle();
        const ff = e.target.files[0];
        const extension = ff.name.split('.').pop().toLowerCase();
        const isValid = fileTypes.indexOf(extension) > -1;
        if (isValid) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target) {
                    const image = new Image();
                    image.crossorigin = "anonymous";
                    const resizedImagePromise = resizeImageIfNeeded(e.target.result)//e.target.result
                    resizedImagePromise.then((resizedImage) => {
                        if (resizedImage !== 'exceed_size') {
                            image.onload = function () {
                                const spec = calculateImage(image);
                                if (style === "image") {
                                    addImageToCanvas(image, spec);
                                } else {
                                    setBackgroundToCanvas(image, spec);
                                };
                            };
                            image.src = resizedImage;
                        } else { showImageWarning(); };
                    }).catch((error) => {
                        console.error("Error resizing the image:", error);
                    });
                };
            };
            reader.readAsDataURL(ff);
        } else { showImageWarning(); };
        //reader.readAsDataURL(ff);
        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        };
        if (backgroundInputRef.current) {
            backgroundInputRef.current.value = "";
        };
    };

    const handleUpload_org = (e, style) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            if (e.target) {
                const image = new Image();
                image.crossorigin = "anonymous";
                image.onload = function () {
                    const spec = calculateImage(image)
                    if (style === "image") {
                        addImageToCanvas(image, spec)
                    } else {
                        setBackgroundToCanvas(image, spec)
                    };

                };
                image.src = e.target.result;
            };
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const calculateImage = (image) => {
        let maxWidth, maxHeight
        if (canvas) {
            maxWidth = canvas.width;
            maxHeight = canvas.height;
        };
        if (maxWidth > image.width && maxHeight > image.height) {
            return { finalAspectRatio: 1, finalHeight: image.height, finalWidth: image.width }
        };

        let imgWidth = image.width; // Current image width
        let imgHeight = image.height; // Current image height
        //console.log("calculate",{imgWidth, imgHeight});
        let widthAspectRatio = maxWidth / imgWidth;
        let heightAspectRatio = maxHeight / imgHeight;

        let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio)

        let finalHeight = imgHeight * finalAspectRatio;
        let finalWidth = imgWidth * finalAspectRatio;

        let imgTop = 0;
        if (maxHeight > finalHeight) {
            imgTop = (Math.round(maxHeight) - Math.round(finalHeight)) / 2;
        };

        let imgLeft = 0;
        if (maxWidth > finalWidth) {
            imgLeft = (Math.round(maxWidth) - Math.round(finalWidth)) / 2;
        };

        return { finalAspectRatio, finalHeight, finalWidth };
    };

    const addImageToCanvas = (image, spec) => {
        if (canvas) {
            const img = new window.fabric.Image(image).scale(spec.finalAspectRatio);
            canvas.add(img);
            canvas.renderAll();
            //)updateResp(canvas, 'addImageToCanvas');
        };
        setMode('s');
    };

    const setBackgroundToCanvas = (image, spec) => {
        if (canvas) {
            const img = new window.fabric.Image(image).scale(spec.finalAspectRatio);
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            canvas.backgroundImage.center();
            canvas.backgroundImage.set('erasable', false);
            canvas.renderAll();
            checkIsBackgroundExist();
            _updateResp(canvas, 'setBackgroundToCanvas');
        };
        setMode('s');
    };

    const selectMode = () => {
        if (canvas) {
            canvas.isDrawingMode = false;
            setMode('s');
        };
    };

    const delObj = () => { if (canvas) delCavObj(canvas); };

    const downloadDraw = async e => { //Click Download
        UI.stopEvent(e);
        beforeHandle();
        //console.log('download !!!');
        if (canvas) {
            if (!BGImgSrc) {
                canvas.backgroundColor = "white";
            } else {
                await loadCanvasBG();
            };
            canvas.renderAll();
            const canvasUrl = canvas.toDataURL({ format: 'jpeg', quality: 0.8 });
            downloadUrl(canvasUrl);
            canvas.backgroundColor = "transparent";
            if (!ignoreRemoveBG) handleRemoveBG(); 
            canvas.renderAll();
        };
    };

    const drawBtn = (icon, cb, exStyle = {}, sel = false) => iconButton('', icon, sel ? 'white' : 'black', sel ? '#31279d' : 'white', cb, true,
        { width: '20px', height: '20px', ...exStyle }, { margin: '0 5px', padding: '5px' })

    const trashBtn = (icon, cb) => iconButton('', icon, 'red', 'white', cb, true, { width: '20px', height: '20px' }, { margin: '0 5px', padding: '5px' })

    const _setShowColor = e => { UI.stopEvent(e); setShowColor(!showColor); }; // btn click hanndler
    const _setShowBrush = e => { UI.stopEvent(e); setShowBrush(!showBrush); }; // btn click hanndler
    const _setShowImage = e => { UI.stopEvent(e); setShowImage(!showImage); }; // btn click hanndler
    const onMouseDown = e => { e.preventDefault() }; //stop Mouse Down

    // old draw bar
    const drawBtnBar = dir => {
        const isH = dir === 'h';
        //:{padding:'5px', top:'-8px',left:(!isTeacher)?'-248px':'-90px'}}
        return (doEdit ? <div className={'DrawCanvasBtnBar' + (isH ? 'Hori' : 'Vert')}>
            {drawUndoRedo ? drawBtn('draw/undo', () => undoCanvas()) : ''}
            {drawUndoRedo ? drawBtn('draw/undo', () => redoCanvas()) : ''}
            {drawBtn('draw/select', () => selectMode(), {}, mode === 's')}
            {drawBtn('draw/pencil', () => setupBrush('pencil'), {}, mode === 'b')}
            {drawBtn('draw/text', () => { addText() })}
            <div className='DrawCanvasPopBtn' tabIndex={0} onBlur={(e) => setShowImage(false)}>
                {drawBtn('draw/image', (e) => _setShowImage(e))}
                {showImage ?
                    <div className='DrawCanvasPopContent f16' style={isH ? { padding: '5px', bottom: '40px', left: (!isTeacher) ? (showEn ? '-98px' : '-56px') : (showEn ? '-18px' : '-12px') }
                        : { padding: '5px', top: '-8px', left: (!isTeacher) ? (showEn ? '-248px' : '-163px') : (showEn ? '-90px' : '-77px') }}
                        onMouseDown={onMouseDown}>
                        <div className='flexRowCenterFit' style={{ whiteSpace: 'nowrap' }}>
                            <div className='clickable' style={{ margin: '5px 10px' }} onClick={(e) => handleImage('image')}>{t('draw.tool.image')}</div>
                            {(!isTeacher) ? verticalLine(26) : ''}
                            {(!isTeacher) ? (hasBG ? <div className='clickable' style={{ margin: '5px 10px' }} onClick={(e) => handleRemoveBG()}>{t('draw.tool.remove-background-image')}</div>
                                : <div className='clickable' style={{ margin: '5px 10px' }} onClick={(e) => handleImage('bgimage')}>{t('draw.tool.background-image')}</div>) : ''}
                        </div>
                    </div> : ''}
                <div style={{ display: 'none' }}>
                    <input ref={imageInputRef} type="file" accept="image/*"
                        onChange={e => handleUpload(e, "image")} />
                    <input ref={backgroundInputRef} type="file" accept="image/*"
                        onChange={e => handleUpload(e, "backgroundImage")} />
                </div>
            </div>
            {(mode === 's' && selected) ? trashBtn('draw/trash', () => delObj())
                : drawBtn('draw/eraser', () => setupBrush('eraser'), {}, mode === 'e')}
            {isH ? verticalLine(26) : horizontalLine(34)}
            <div className='DrawCanvasPopBtn' tabIndex={0} onBlur={(e) => setShowBrush(false)}>
                {drawBtn(getBrushIcon(brushSize), (e) => _setShowBrush(e))}
                {showBrush ? <div className='DrawCanvasPopContent' style={
                    isH ? { bottom: '40px', left: '-6px' } : { top: '-65px', left: '-65px' }}
                    onMouseDown={onMouseDown}>
                    {brushOptions(sizes, setBrushSize)}
                </div> : ''}
            </div>
            <div className='DrawCanvasPopBtn clickable' tabIndex={1} onClick={_setShowColor} onBlur={(e) => setShowColor(false)}
                style={{ backgroundColor: getRGBA(bcolor.rgb) }}>
                {showColor ? <div className='DrawCanvasPopContent' style={
                    isH ? { bottom: '40px', left: '-100px' } : { top: '-100px', left: '-250px' }}
                    onClick={(e) => { UI.stopEvent(e) }}>
                    <ChromePicker color={bcolor} onChange={setBrushColor} />
                </div> : ''}
            </div>
            {isH ? verticalLine(26) : horizontalLine(34)}
            {drawBtn('general/download', downloadDraw)}
        </div> : '');
    };

    const handleSelect = () => {
        beforeHandle();
        selectMode();
        setSelectedTool("select");
    };
    const handlePen = (pencilType) => {
        beforeHandle();
        handleDisselect();
        setupBrush(pencilType);
        setSelectedTool(pencilType);
        if (pencilType !== null) setPencilType(pencilType);
    };
    //////////////////////////////////////////////////////////////// newDrawFunc
    const getDrawCursor = (cursorType = "") => {
        const isH = cursorType === "highlight";
        const brushS = isH ? hbrushSize : brushSize;
        const cursorColor = (cursorType === "eraser") ? "rgba(255, 255, 255,0.5)" : getRGBA(isH ? hcolor.rgb : bcolor.rgb);
        const cursorStrokeWidth = (cursorType === "eraser") ? 3 : 0
        const cursorStrokeColor = (cursorType === "eraser") ? "#808080" : ""

        const circle = `<svg xmlns="http://www.w3.org/2000/svg" width="${brushS}" height="${brushS}"><circle cx="${brushS / 2}" cy="${brushS / 2}" r="${brushS / 3}" shape-rendering="geometricPrecision" stroke-miterlimit='2' stroke-width="${cursorStrokeWidth}" stroke="${cursorStrokeColor}" fill="${cursorColor}" /></svg>`;
        return `url(data:image/svg+xml;base64,${window.btoa(circle)}) ${brushS / 2} ${brushS / 2}, crosshair`;
    };

    const changeCursor = () => {
        if (canvas) {
            switch (selectedTool) {
                case "pencil":
                case "highlight":
                    canvas.freeDrawingCursor = getDrawCursor(selectedTool);
                    break;
                case "eraser":
                    canvas.freeDrawingCursor = getDrawCursor(selectedTool);
                    break;
                default:
                    canvas.freeDrawingCursor = 'grab';
                    break;
            };
        };
    };

    const handleDisselect = () => {
        if (canvas) canvas.discardActiveObject().renderAll();
    };

    const handleLine = (lineType) => {
        beforeHandle();
        if (!_newDrawFunc) return;
        handleDisselect();
        setSelectedTool("straightLine");
        setLineType(lineType);

        const fabric = window.fabric;
        if (canvas && fabric) {
            canvas.isDrawingMode = false
            switch (lineType) {
                case "straightLine":
                case "dashLine":
                    let setting = {
                        name: lineType,
                        top: isOverLay?120:50,
                        left: 30,
                        stroke: getRGBA(bcolor.rgb),
                        strokeWidth: brushSize,
                        strokeUniform: true,
                        objectCaching: false,
                        transparentCorners: false,
                        perPixelTargetFind: true,
                    };

                    if (lineType === "dashLine") {
                        setting = { ...setting, strokeDashArray: [5, 3] };
                    };

                    const line = new fabric.Polyline([{ x: 0, y: 0 }, { x: 100, y: 0 }], setting);
                    canvas.add(line);
                    editLine(line);
                    break;
                case "arrow":
                    const settingA = {
                        name: lineType,
                        top: 50,
                        left: 30,
                        originX: "left",
                        originY: "top",                        
                        stroke: getRGBA(bcolor.rgb),
                        strokeLineJoin: 'round',
                        strokeWidth: brushSize,
                        strokeUniform: true,
                        objectCaching: false,
                        transparentCorners: false,
                        perPixelTargetFind: true,
                        //oupType: 'oupArrow',
                    };

                    const arrow = new fabric.ArrowLine([{ x: 0, y: 0 }, { x: 100, y: 0 }], settingA);
                    canvas.add(arrow);
                    editLine(arrow);
                    break;
            };
        };
    };

    const handleShape = (shapeType) => {
        beforeHandle();
        if (!_newDrawFunc) return;
        const fabric = window.fabric;
        if (!fabric) return;
        handleDisselect();
        setShapeType(shapeType);
        if (canvas) {
            canvas.isDrawingMode = false;
            switch (shapeType) {
                case "circle":
                    setSelectedTool("circle");
                    fabric.Object.prototype.noScaleCache = false;
                    const circle = new fabric.Circle({
                        name: "circle",
                        radius: 65,
                        left: 30,
                        top: 50,
                        fill: 'transparent',
                        stroke: getRGBA(bcolor.rgb),
                        strokeWidth: brushSize,
                        strokeUniform: true
                    });
                    canvas.add(circle);
                    canvas.setActiveObject(circle);
                    // handleSelect()
                    canvas.requestRenderAll();
                    break;
                case "polygon":
                    setSelectedTool("polygon");
                    drawPolyMode(true);
                    break;
            };
        };
    };

    const afterUndoRedo = () => {
        pasteObjects();
        canvas.renderAll();
        fabricHistoryRef.current.historyLock = false;
        //console.log("history unlocked !!");
        _updateResp(canvas);
    };

    //const is_allow_undo = fabricHistoryRef.current?.undo_history.length > 0;
    //const is_allow_redo = fabricHistoryRef.current?.redo_history.length > 0;
    const handleHistory = (action) => {
        if (!_newDrawFunc || gVar.drawMode) return;
        if (canvas) {
            const { maxHistorySteps, undo_history, redo_history } = fabricHistoryRef.current;
            let canvasContent = getCVData(canvas);

            // Starting
            const is_allow_undo = undo_history.length > 1;
            const is_allow_redo = redo_history.length > 0;
            //console.log({action, is_allow_undo,is_allow_redo});
            if ((action === FabricHistoryAction.UNDO && is_allow_undo) || (action === FabricHistoryAction.REDO && is_allow_redo)) {            
                fabricHistoryRef.current.historyLock = true;
            };
            /*
            switch (action) {
                case FabricHistoryAction.UNDO:
                case FabricHistoryAction.REDO:                    
                    fabricHistoryRef.current.historyLock = true;                    
                    break;
            };
            */

            // Action
            //console.log({action, is_allow_undo, is_allow_redo});
            switch (action) {
                case FabricHistoryAction.UNDO:
                    if (is_allow_undo) {
                        //console.log("debug undo", is_allow_undo, undo_history.length, fabricHistoryRef.current.undo_history.length);
                        // Display Second Last undo element
                        const lastElement = undo_history.pop();
                        canvasContent = undo_history.slice(-1)[0];
                        fabricHistoryRef.current.undo_history = undo_history;
                        fabricHistoryRef.current.redo_history = [...redo_history, lastElement];
                        //console.log("debug undo", is_allow_undo, undo_history.length, fabricHistoryRef.current.undo_history.length);
                    };
                    break;
                case FabricHistoryAction.REDO:
                    if (is_allow_redo) {
                        //console.log("debug redo", is_allow_redo, redo_history.length, fabricHistoryRef.current.redo_history.length);
                        const lastElement = redo_history.pop();
                        // Display Last redo element
                        canvasContent = lastElement;
                        fabricHistoryRef.current.redo_history = redo_history;
                        fabricHistoryRef.current.undo_history = [...undo_history, lastElement];
                        //console.log("debug redo", is_allow_redo, redo_history.length, fabricHistoryRef.current.redo_history.length);
                    };
                    break;
                case FabricHistoryAction.LOG:
                    if (!fabricHistoryRef.current.historyLock) {
                        //console.log("do history log:", canvasContent?1:0);
                        fabricHistoryRef.current.undo_history = [...undo_history, canvasContent].slice(maxHistorySteps);
                        fabricHistoryRef.current.redo_history = [];
                        //console.log("debug log", undo_history.length, fabricHistoryRef.current.undo_history.length, redo_history.length, fabricHistoryRef.current.redo_history.length);
                    }; // else { console.log("history Locked !!"); };
                    break;
                case FabricHistoryAction.CLEAR:
                    //console.log("debug clear");
                    fabricHistoryRef.current.undo_history = [];
                    fabricHistoryRef.current.redo_history = [];
                    fabricHistoryRef.current.historyLock = false;
                    break;
            };

            //Then, render (undo,redo)
            switch (action) {
                case FabricHistoryAction.UNDO:
                case FabricHistoryAction.REDO:
                    if ((action === FabricHistoryAction.UNDO && is_allow_undo) || (action === FabricHistoryAction.REDO && is_allow_redo)) {
                        //console.log("do history update canvas", canvasContent?1:0);
                        //console.log("debug render", action);
                        /*canvas.loadFromJSON(canvasContent, function () {
                            canvas.renderAll();
                            fabricHistoryRef.current.historyLock = false;
                        });*/
                        if (canvasContent) { canvas.loadFromJSON(canvasContent, afterUndoRedo);
                        } else {
                            //console.log("history unlocked !! 2");
                            fabricHistoryRef.current.historyLock = false;
                        };
                    };
                    break;
            };
        };
    };
    const clearCanvasHistory = () => {
        handleHistory(FabricHistoryAction.CLEAR);
        handleHistory(FabricHistoryAction.LOG);
    };

    const handleSelection = (objs) => {
        setSelected(objs.selected ? true : false);
        //setIsSelectedObject(objs.selected ? true : false);
    };

    const handleMouseDown = () => {
        //check selected object
        if (canvas) {
            gVar.mDown = 1;
            setSelected(canvas.getActiveObjects().length > 0);
            //setIsSelectedObject(prev => canvas.getActiveObjects().length > 0)
        };
    };

    const addHistoryListener = () => {
        if (!canvas) return;
        canvas.on({
            //'selection:created': handleSelection,
            // 'selection:updated': handleSelection,
            // 'selection:cleared': handleSelect,
            'object:added': handleAddedModified,
            'object:modified': handleAddedModified,
            'object:removed': handleAddedModified,
            //'mouse:down': handleMouseDown,
            'erasing:end': handleAddedModified,
            // 'mouse:up': handleMouseUp,
            // 'mouse:move': handleMouseMove,
            // 'mouse:moving': handleMouseMoving,
            // 'before:selection:cleared': handleAddedModified,
        });
    };

    const initCanvasHistory = () => {
        //Only for empty canvas, not for reload
        if (!_newDrawFunc) return;
        clearCanvasHistory();
        addHistoryListener();
    };

    const handleAddedModified = () => {
        if (gVar.drawReady) {
            handleHistory(FabricHistoryAction.LOG); 
        };
    };    

    const handleUndo = () => {
        beforeHandle();
        if (!_newDrawFunc) return;
        handleHistory(FabricHistoryAction.UNDO);
    };
    const handleRedo = () => {
        beforeHandle();
        if (!_newDrawFunc) return;
        handleHistory(FabricHistoryAction.REDO);
    };

    const polygonPositionWrapper = (anchorIndex) => {
        const fabric = window.fabric;
        return function (dim, finalMatrix, fabricObject) {
            var x = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                y = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y);
            if (!fabricObject.canvas) return { x: x, y: y };
            return fabric.util.transformPoint(
                { x: x, y: y },
                fabric.util.multiplyTransformMatrices(
                    fabricObject.canvas.viewportTransform,
                    fabricObject.calcTransformMatrix()
                )
            );
        };
    };

    const getObjectSizeWithStroke = (object) => {
        const fabric = window.fabric;
        var stroke = new fabric.Point(
            object.strokeUniform ? 1 / object.scaleX : 1,
            object.strokeUniform ? 1 / object.scaleY : 1).multiply(object.strokeWidth);
        return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
    };

    const polygonActionHandler = (eventData, transform, x, y) => {
        const fabric = window.fabric;
        var polygon = transform.target,
            currentControl = polygon.controls[polygon.__corner],
            mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
            //polygonBaseSize = polygon._getNonTransformedDimensions(),
            polygonBaseSize = getObjectSizeWithStroke(polygon),
            size = polygon._getTransformedDimensions(0, 0),
            finalPointPosition = {
                x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
            };
        polygon.points[currentControl.pointIndex] = finalPointPosition;
        return true;
    };

    const polygonAnchorWrapper = (anchorIndex, fn) => {
        const fabric = window.fabric;
        return function (eventData, transform, x, y) {
            var fabricObject = transform.target,
                absolutePoint = fabric.util.transformPoint({
                    x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                    y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
                }, fabricObject.calcTransformMatrix()),
                actionPerformed = fn(eventData, transform, x, y),
                newDim = fabricObject._setPositionDimensions({}),
                polygonBaseSize = getObjectSizeWithStroke(fabricObject),
                //polygonBaseSize = fabricObject._getNonTransformedDimensions(),
                newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
                newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
            fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
            return actionPerformed;
        };
    };

    const editLine = (obj) => {
        const fabric = window.fabric;
        if (canvas && obj) {
            if (['polyline','arrowline'].includes(obj.type.toLowerCase())) {
                const poly = obj;
                canvas.setActiveObject(poly);
                const lastControl = poly.points.length - 1;
                poly.cornerStyle = 'circle';
                poly.cornerSize = _ctPointSize;
                poly.cornerColor = 'rgba(0,0,255,0.3)';
                poly.controls = poly.points.reduce((acc, point, index) => {
                    acc['p' + index] = new fabric.Control({
                        positionHandler: polygonPositionWrapper(index),
                        actionHandler: polygonAnchorWrapper(index > 0 ? index - 1 : lastControl, polygonActionHandler),
                        actionName: 'modifyLine',
                        pointIndex: index
                    });
                    return acc;
                }, {});
                poly.hasBorders = false;
                canvas.requestRenderAll();
            };
        };
    };

    const editPolygon = (obj) => {
        const fabric = window.fabric;
        if (canvas && obj) {
            if (['polygon'].includes(obj.type.toLowerCase())) {
                const poly = obj;
                canvas.setActiveObject(poly);
                const lastControl = poly.points.length - 1;
                poly.cornerStyle = 'circle';
                poly.cornerSize = _ctPointSize;
                poly.cornerColor = 'rgba(0,0,255,0.3)';
                poly.controls = poly.points.reduce((acc, point, index) => {
                    acc['p' + index] = new fabric.Control({
                        positionHandler: polygonPositionWrapper(index),
                        actionHandler: polygonAnchorWrapper(index > 0 ? index - 1 : lastControl, polygonActionHandler),
                        actionName: 'modifyPolygon',
                        pointIndex: index
                    });
                    return acc;
                }, {});
                poly.hasBorders = false;
                canvas.requestRenderAll();
            };
        };
    };

    /*
    const editPolygon_org = () => {
        const fabric = window.fabric;
        if (!canvas || !fabric) return;
        let activeObject = canvas.getActiveObject();
        if (!activeObject) {
            activeObject = canvas.getObjects()[0];
            canvas.setActiveObject(activeObject);
        };

        activeObject.edit = true;
        activeObject.objectCaching = false;
                
        const lastControl = activeObject.points.length - 1;
        activeObject.cornerStyle = 'circle';
        activeObject.cornerSize = isMobileScreen() ? ctSizeMobile:ctSizeDesktop;
        activeObject.cornerColor = 'rgba(0,0,255,0.5)';
        activeObject.controls = activeObject.points.reduce((acc, point, index) => {
            acc['p' + index] = new fabric.Control({
                positionHandler: polygonPositionWrapper(index),
                actionHandler: polygonAnchorWrapper(index > 0 ? index - 1 : lastControl, polygonActionHandler),
                actionName: 'modifyPolygon',
                pointIndex: index,
            });
            return acc;
        }, {});

        activeObject.hasBorders = false;
        canvas.requestRenderAll();
    };
    */
    const drawPolyMode = (flag) => {
        if (flag) {
            //console.log('polygon true');
            if (needDrawDeb && gVar.dirty) _updateData(canvas); // for not in draw mode, and canvas has updated
            canvas.selection = false;
            canvas.hoverCursor = "crosshair";
            gVar.drawMode = true;
        } else {
            //console.log('drawPolyMode false');
            gVar.activeLine = null;
            gVar.activeShape = null;
            gVar.lineArray = [];
            gVar.pointArray = [];
            canvas.selection = true;
            canvas.hoverCursor = "move";
            gVar.drawMode = false;
        };
    };

    const generatePolygon = (pointArray, active=1) => {
        const fabric = window.fabric;
        if (!canvas || !fabric) return;
        const points = [];
        // collect points and remove them from canvas
        for (const point of pointArray) {
            points.push({ x: point.left, y: point.top });
            canvas.remove(point);
        };

        // remove lines from canvas
        for (const line of gVar.lineArray) { canvas.remove(line); };

        // remove selected Shape and Line 
        canvas.remove(gVar.activeShape).remove(gVar.activeLine);

        // create polygon from collected points
        if (points.length > 2) {
            const polygon = new fabric.Polygon(points, {
                id: new Date().getTime(),
                //stroke: '#0084ff',
                fill: false,
                stroke: gVar.bcolor, //getRGBA(bcolor.rgb),
                strokeWidth: gVar.bsize, //brushSize,            
                objectCaching: false,
                moveable: false,
                //selectable: false
                strokeLineJoin: 'round',
                strokeUniform: true,
                //perPixelTargetFind: true,          
                transparentCorners: false,
            });
            drawPolyMode(false); // must call once here before add polygon
            canvas.add(polygon);
            if (active) {
                canvas.setActiveObject(polygon);
                editPolygon(polygon);
            };            
        } else drawPolyMode(false); // must call again here
    };

    const addPolygonPoint = (options) => {
        //console.log('addPolygonPoint');
        const fabric = window.fabric;
        if (!canvas || !fabric) return;
        const pX = options.pointer.x; // -radius;
        const pY = options.pointer.y;
        const pointOption = {
            id: new Date().getTime(),
            radius: _ctPointSize/2,
            fill: '#ffffff',
            stroke: '#333333',
            strokeWidth: 0.5,
            //left: options.e.layerX / canvas.getZoom(),
            //top: options.e.layerY / canvas.getZoom(),
            left: pX,
            top: pY,
            selectable: false,
            hasBorders: false,
            hasControls: false,
            originX: 'center',
            originY: 'center',
            objectCaching: false,
        };
        const point = new fabric.Circle(pointOption);

        const pTotal = gVar.pointArray.length;
        if (pTotal === 0) {
            // fill first point with red color
            point.set({
                //radius: isMobileScreen() ?10:5,
                fill: 'red'
            });
        };

        const _pX = pTotal ? gVar.pointArray[pTotal-1].left: pX;
        const _pY = pTotal ? gVar.pointArray[pTotal-1].top: pY;
        const linePoints = [_pX, _pY, pX, pY];
        const lineOption = {
            //strokeWidth: 2,
            //fill: '#999999',
            fill: gVar.bcolor, //getRGBA(bcolor.rgb),
            stroke: gVar.bcolor, //getRGBA(bcolor.rgb),
            strokeWidth: gVar.bsize, //brushSize,            
            originX: 'center',
            originY: 'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
            objectCaching: false,
        };
        const line = new fabric.Line(linePoints, lineOption);
        if (gVar.activeLine) canvas.remove(gVar.activeLine);
        const activeLine = new fabric.Line([pX, pY, pX, pY], lineOption);

        line.class = 'line';
        activeLine.class = 'line';
        gVar.activeLine = activeLine;
        gVar.pointArray.push(point);
        gVar.lineArray.push(line);

        canvas.add(line);
        canvas.add(activeLine);
        canvas.add(point);
    };

    const _selectCustomeEdit = (obj) => {
        //const obj = options.target;
        if ( obj && !isPen()) {
            //console.log("set edit check: ", obj.type);
            if (['polyline','arrowline'].includes(obj.type.toLowerCase())) {
                editLine(obj);
            } else if (obj.type === 'polygon') {
                editPolygon(obj);
            };
        };
    };

    const onPolygonMouseDown = (options) => {
        gVar.mDown = 1;
        setSelected(canvas.getActiveObjects().length > 0);
        if (gVar.drawMode) {
            const pointArray = gVar.pointArray;
            if (options.target && pointArray.length && options.target.id === pointArray[0].id) {
                //console.log("click first pt !! finish poly !!");
                generatePolygon(pointArray);
            } else {
                //console.log("add points !!");
                addPolygonPoint(options);
            };
        }; /* else {
            if (options.target && !isPen()) {
                if (['polyline','arrowline'].includes(options.target.type.toLowerCase())) {
                    editLine(options.target);
                } else if (options.target.type === 'polygon') {
                    editPolygon();
                };
            };
        };*/
    };

    const onPolygonMouseUp = (options) => {
        gVar.mDown = 0;
        if (!canvas) return;
        //canvas.isDragging = false;
        if (!gVar.drawMode) canvas.selection = true;
    };

    const onPolygonMouseMove = (options) => {
        if (!canvas) return;
        /*if (canvas.isDragging) {
            var e = options.e;
            canvas.viewportTransform[4] += e.clientX - canvas.lastPosX;
            canvas.viewportTransform[5] += e.clientY - canvas.lastPosY;
            canvas.requestRenderAll();
            canvas.lastPosX = e.clientX;
            canvas.lastPosY = e.clientY;
        };*/
        if (gVar.drawMode && !gVar.mDown) {
            if (gVar.activeLine && gVar.activeLine.class === 'line') {
                //console.log("gVar.activeLine", gVar.activeLine);
                //console.log("update activeLine");
                const pointer = canvas.getPointer(options.e);
                gVar.activeLine.set({
                    x2: pointer.x,
                    y2: pointer.y
                });
            };
            canvas.renderAll();
        };
    };

    const onPolygonObjectMove = (option) => {
        const object = option.target;
        if (object.type === "polygon") {
            object._calcDimensions();
            object.setCoords();
            canvas.renderAll();
        };
    };

    const onPolygonMouseOut = (option) => {
        const object = option.target;
        if (object && object.type === "polygon") {
            console.log("onPolygonMouseOut");
        };
    };

    //event
    const addPolygonListener = () => {
        if (!canvas) return;
        canvas.on('mouse:down', onPolygonMouseDown);
        canvas.on('mouse:up', onPolygonMouseUp);
        canvas.on('mouse:move', onPolygonMouseMove);
        //canvas.on('mouse:out', onPolygonMouseOut);
        canvas.on('object:moving', onPolygonObjectMove);
    };

    const initDrawPolygon = () => {
        if (!_newDrawFunc) return;
        addPolygonListener();
    };

    const initArrow = () => {
        const fabric = window.fabric;
        if (fabric && !fabric.ArrowLine) {
            fabric.ArrowLine = fabric.util.createClass(fabric.Polyline, {
                type: 'ArrowLine',
                initialize: function (points, options) {
                    options || (options = {});
                    this.callSuper('initialize', points, options);
                    this.set('oupType', options.oupType || '');
                    this.set('selectable', options.selectable || true);
                    this.set('evented', options.evented || true);
                    this.set('objectCaching', options.objectCaching || false);
                    this.set('transparentCorners', options.transparentCorners || false);
                },
                toObject: function () {
                    return fabric.util.object.extend(this.callSuper('toObject'), {
                        oupType: this.get('oupType'),
                        selectable: this.get('selectable'),
                        evented: this.get('evented'),
                        objectCaching: this.get('objectCaching'),
                        transparentCorners: this.get('transparentCorners')
                    });
                },
                _render: function (ctx) {
                    //this.callSuper('_render', ctx);
                    //console.log('ArrowLine render !!', this.points);
                    const tip = this.points[1];
                    const end = this.points[0];
                    const rr = computeArrow({
                        fromX: end.x - this.pathOffset.x, fromY: end.y - this.pathOffset.y,
                        toX: tip.x - this.pathOffset.x, toY: tip.y - this.pathOffset.y,
                        len: this.strokeWidth * 2,
                    });
                    //console.log({tip, end, rr, pathOffset:this.pathOffset});
                    ctx.fillStyle = this.stroke;
                    ctx.beginPath();
                    ctx.moveTo(rr.points[0].x, rr.points[0].y);
                    for (let ii = 1; ii < rr.points.length - 1; ii++) {
                        ctx.lineTo(rr.points[ii].x, rr.points[ii].y);
                    };
                    ctx.closePath();
                    //ctx.stroke();
                    ctx.fill();
                },
            });

            fabric.ArrowLine.fromObject = function (object, callback) {
                return fabric.Object._fromObject('ArrowLine', object, callback, 'points');
            };
        };
    };
    /*
    const onCanvasMouseMove = (options) => {
        if (!canvas) return;
        console.log('canvas mouse move !!', canvas.altActionKey);
        if (canvas.altActionKey === "shiftKey") return;
    };
    */
    const _cavSelectCheck = (evt) => {
        if (gVar.drawMode) {
            //console.log("deselect all !!");
            canvas.discardActiveObject();
            return;
        };
        const mObj = canvas.getActiveObject();
        if (mObj) {
            _selectCustomeEdit(mObj);
            setMode('s');
            if (mObj.type === 'activeSelection') {
                //console.log('activeSelection: ', mObj.type);
                mObj.set("lockSkewingX", true);
                mObj.set("lockSkewingY", true);
                mObj.setControlsVisibility({
                    ml: false,
                    mt: false,
                    mr: false,
                    mb: false,
                });
                canvas.set("uniformScaling", true);
                canvas.set("uniScaleKey", null);
            };
            mObj.set("lockScalingFlip", true);
        } else {
            setSelected(false);
        };
    };

    const initCanvasEvent = () => {
        if (!canvas) return;
        canvas.on('selection:created', (evt) => {
            //console.log('selection:created');
            //if (gVar.drawMode) finishPoly();
            _cavSelectCheck(evt);
        });
        canvas.on('selection:updated', (evt) => {
            //console.log("selection:updated");
            _cavSelectCheck(evt);
        });
        canvas.on('selection:cleared', (evt) => {
            canvas.set("uniformScaling", false);
            canvas.set("uniScaleKey", "shiftKey");
            setSelected(false);
        });
        canvas.on('mouse:out', (evt) => {
            if ( !evt.target && gVar.drawMode ) finishPoly();
            if ( !gVar.drawMode && gVar.dirty ) { // for debounce
                //console.log('canvas mouse:out, save data');
                _updateData(canvas);
            };
        });
        //canvas.set("altActionKey", null);
        //canvas.on('mouse:move', onCanvasMouseMove);
    };

    /*
    const canvasLostFocus = (e) => {
        console.log("canvase lost focus "+id);
        if ( gVar.drawMode ) finishPoly();
        if ( !gVar.drawMode && gVar.dirty ) { // for debounce
            //console.log('canvas mouse:out, save data');
            _updateData(canvas);
        };
    };
    */

    const handleText = () => {
        beforeHandle();
        addText();
        setSelectedTool("text");
    };
    const handleSave = () => { beforeHandle(); handleImage("image"); };
    const handleEraser = () => {
        beforeHandle();
        //console.log("eraser check:", {mode, selected});
        if (mode === 's' && selected) {
            delObj();
            canvas.renderAll();
        } else {
            setupBrush("eraser");
            setSelectedTool("eraser");
        };
    };

    const handleCloseBar = (e) => {
        //console.log("close overlay draw bar");
        setVisDrawLayer && setVisDrawLayer(0);
    };

    const Imagepopover = () => <Popover><div className={"d-flex flex-row justify-content-center align-items-center gap-3 p-2 shadow"}>
        <div role={"button"} className={`draw-image-option text-nowrap `} onClick={() => handleImage("image")}>
            <div className={"mx-2 my-1 rounded"}>{t('draw.tool.image')}</div>
        </div>
        {(!hasBG && !isTeacher) ?
            <div role={"button"} className={`draw-image-option text-nowrap `} onClick={() => handleImage("backgroundImage")}>
                <div className={"mx-2 my-1 rounded"}>{t('draw.tool.background-image')}</div>
            </div> : ''}
        {(hasBG && !isTeacher) ?
            <div role={"button"} className={`draw-image-option text-nowrap remove-background-image`} onClick={() => handleRemoveBG()}>
                <div className={"mx-3 my-1 rounded"}>{t('draw.tool.remove-background-image')}</div>
            </div> : ''}
    </div></Popover>;

    const Brushpopover = () => <Popover><div className={"d-flex  flex-row flex-md-column justify-content-center align-items-center gap-3 p-2 shadow"}>
        {sizes.map((size, idx) => <div key={idx} role={"button"} className={`brush-width ${brushSize === size ? "active" : ""}`}
            onClick={e => {
                if (selectedTool === "highlight") {
                    setHbrushSize(size);
                    handlePen("highlight");
                } else {
                    setBrushSize(size);
                    if (selectedTool === "pencil") {
                        handlePen("pencil");
                    } else if (selectedTool === "eraser") {
                        handlePen("eraser");
                    } else if (selectedTool === "text") {
                        handleText();
                    };
                }
            }}>
            <div className={"mx-2 my-1 rounded-circle bg-black"} style={{ width: `${size}px`, height: `${size}px` }}></div>
        </div>)}
    </div></Popover>

    const Colorpopover = () => <Popover><ChromePicker color={selectedTool === "highlight" ? hcolor.rgb : bcolor.rgb} onChange={setBrushColor} className={"shadow"} /></Popover>

    const Pencilpopover = () => <Popover><div className={"d-flex flex-row flex-md-column justify-content-center align-items-center gap-3 p-2 shadow"}>
        <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handlePen("pencil")}>
            <div className={"mx-2 my-1 rounded"}><CpIco src={IconList.draw.pencil} /></div>
        </div>
        <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handlePen("highlight")}>
            <div className={"mx-2 my-1 rounded"}><CpIco src={IconList.draw.highlight} /></div>
        </div>
    </div></Popover>;

    const Linepopover = () => <Popover>
        <div className={"d-flex flex-row flex-md-column justify-content-center align-items-center gap-3 p-2 shadow"}>
            <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handleLine("straightLine")}>
                <div className={"mx-2 my-1 rounded"}> <CpIco src={IconList.draw.straightLine} /></div>
            </div>
            <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handleLine("dashLine")}>
                <div className={"mx-2 my-1 rounded"}> <CpIco src={IconList.draw.dashLine} /></div>
            </div>
            <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handleLine("arrow")}>
                <div className={"mx-2 my-1 rounded"}> <CpIco src={IconList.draw.arrow} /></div>
            </div>
        </div>
    </Popover>;

    const Polygonpopover = () => <Popover>
        <div className={"d-flex flex-row flex-md-column justify-content-center align-items-center gap-3 p-2 shadow"}>
            <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handleShape("circle")}>
                <div className={"mx-2 my-1 rounded"}><CpIco src={IconList.draw.circle} /></div>
            </div>
            <div role={"button"} className={`draw-option text-nowrap `} onClick={() => handleShape("polygon")}>
                <div className={"mx-2 my-1 rounded"}><CpIco src={IconList.draw.polygon} /></div>
            </div>
        </div>
    </Popover>;

    // enhance draw bar
    const enhanceBar = dir => {
        const widerscreen = dir === 'h';
        const isH = selectedTool === 'highlight';
        const _brushSize = isH ? hbrushSize : brushSize;
        const _bcolor = isH ? hcolor : bcolor;
        return <>{doEdit ? <div id={drawBarID} ref={toolContainer} className={`draw-editor-toolbar ${widerscreen ? "wider-screen" : ""}`} style={{ paddingTop: '5px' }}>
            <div className={`draw-editor-toolbar-container  ${widerscreen ? "d-grid flex-column align-items-center" : "d-inline-flex flex-row flex-md-column"} justify-content-center ${widerscreen ? "rounded" : "rounded"} px-3 py-2 gap-2 flex-wrap flex-md-nowrap mx-auto `}>
                <div className={`draw-editor-tool draw-select ${selectedTool === "select" ? "active" : ""}`} onClick={() => { handleSelect() }}>
                    <Button variant={""}><CpIco src={IconList.draw.select} /></Button>
                </div>
                <div className={`draw-editor-tool d-none d-md-flex ${selectedTool === "pencil" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "left" : "left"}`} overlay={Pencilpopover()} rootClose>
                        <Button variant={""} className="draw-group">
                            <CpIco src={pencilType === "pencil" ? IconList.draw.group.pencilDesktop : IconList.draw.group.highlightDesktop} />
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool d-flex d-md-none ${selectedTool === "pencil" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "top" : "top"}`} overlay={Pencilpopover()} rootClose>
                        <Button variant={""} className="draw-group">
                            <CpIco src={pencilType === "pencil" ? IconList.draw.group.pencilMobile : IconList.draw.group.highlightMobile} />
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool d-none d-md-flex ${selectedTool === "straightLine" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "left" : "left"}`} overlay={Linepopover()} rootClose>
                        <Button variant={""} className="draw-group"><CpIco src={getLineIconDesktop(lineType)} /></Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool d-flex d-md-none ${selectedTool === "straightLine" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "top" : "top"}`} overlay={Linepopover()} rootClose>
                        <Button variant={""} className="draw-group"><CpIco src={getLineIconMobile(lineType)} /></Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool d-none d-md-flex ${selectedTool === "circle" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "left" : "left"}`} overlay={Polygonpopover()} rootClose>
                        <Button variant={""} className="draw-group">
                            <CpIco src={shapeType === "circle" ? IconList.draw.group.circleDesktop : IconList.draw.group.polygonDesktop} />
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool d-flex d-md-none ${selectedTool === "circle" ? "active" : ""}`}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "top" : "top"}`} overlay={Polygonpopover()} rootClose>
                        <Button variant={""} className="draw-group">
                            <CpIco src={shapeType === "circle" ? IconList.draw.group.circleMobile : IconList.draw.group.polygonMobile} />
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool draw-text  ${selectedTool === "text" ? "active" : ""}`} onClick={() => { handleText() }}>
                    <Button variant={""}><CpIco src={IconList.draw.text} /></Button>
                </div>
                {(isTeacher || !bgBtn) ?
                    <div className={`draw-editor-tool draw-undo`} onClick={isAT ? (e) => doAddMediaImg() : null}>
                        {!isAT ? <div className={"visually-hidden"}>
                            <input ref={imageInputRef} id={`${id}-image`} type="file" accept="image/*" className={""} onChange={e => handleUpload(e, "image")} />
                        </div> : ''}
                        <Button variant={""} onClick={handleSave}><CpIco src={IconList.draw.image} /></Button>
                    </div> :
                    <div className="draw-image">
                        <div className={`draw-editor-tool d-none d-md-block ${selectedTool === "image" ? "active" : ""}`}>
                            <div className={"visually-hidden"}>
                                <input ref={imageInputRef} id={`${id}-image`} type="file" accept="image/*" className="" onChange={e => handleUpload(e, "image")} />
                                <input ref={backgroundInputRef} id={`${id}-backgroundimage`} type="file" accept="image/*" className="" onChange={e => handleUpload(e, "backgroundImage")} />
                            </div>
                            <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "left" : "left"}`} overlay={Imagepopover()} rootClose>
                                <Button variant={""} className="draw-group"><CpIco src={IconList.draw.group.imageDesktop} /></Button>
                            </OverlayTrigger>
                        </div>
                        <div className={`draw-editor-tool d-block d-md-none  ${selectedTool === "image" ? "active" : ""}`}>
                            <div className={"visually-hidden"}>
                                <input ref={imageInputRef} id={`${id}-image`} type="file" accept="image/*" className="" onChange={e => handleUpload(e, "image")} />
                                <input ref={backgroundInputRef} id={`${id}-backgroundimage`} type="file" accept="image/*" className="" onChange={e => handleUpload(e, "backgroundImage")} />
                            </div>
                            <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "top" : "top"}`} overlay={Imagepopover()} rootClose>
                                <Button variant={""} className="draw-group"><CpIco src={IconList.draw.group.imageMobile} /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>}
                <div className={`draw-editor-tool draw-eraser ${selected ? "selected-object-trash" : ""} ${selectedTool === "eraser" && (!selected) ? "active" : ""}`}
                    onClick={() => { handleEraser() }}>
                    <Button variant={""}><CpIco src={selected ? IconList.draw.trash : IconList.draw.eraser} /></Button>
                </div>
                <hr className={"m-0 d-none d-md-block"} />
                <hr className={"flex-basis m-0 d-block d-md-none"} />
                <div className={`draw-editor-tool draw-brush-width d-none d-md-flex ${widerscreen ? "" : ""}`} ref={colorPickerRef}>
                    <OverlayTrigger container={toolContainer} key={_brushSize} trigger="click" placement={`${widerscreen ? "left" : "left"}`} overlay={Brushpopover()} rootClose>
                        <Button variant={""} className={"d-inline-flex align-items-center fs-8"}>{getSizeName(_brushSize)}</Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool draw-brush-width d-flex d-md-none ${widerscreen ? "" : ""}`} ref={colorPickerRef}>
                    <OverlayTrigger container={toolContainer} key={_brushSize} trigger="click" placement={`${widerscreen ? "top" : "top"}`} overlay={Brushpopover()} rootClose>
                        <Button variant={""} className={"d-inline-flex align-items-center fs-8"}>{getSizeName(_brushSize)}</Button>
                    </OverlayTrigger>
                </div>
                <div className={`draw-editor-tool draw-color ${widerscreen ? "" : ""}`} ref={colorPickerRef}>
                    <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "left" : "top"}`} overlay={Colorpopover()} rootClose>
                        <Button variant={""} className={"d-inline-flex align-items-center"}>
                            <span className={"color-pantone-container position-relative overflow-hidden"}>
                                <span className={"color-pantone position-absolute top-0 start-0 w-100 h-100"} style={{ background: getRGBA(_bcolor.rgb) }}></span>
                            </span>
                        </Button>
                    </OverlayTrigger>
                </div>

                <div className={`vr ${widerscreen ? "d-none" : "d-block d-md-none"}`} />
                <hr className={"m-0 d-none d-md-block"} />

                <div className={`draw-editor-tool draw-undo`}>
                    <Button variant={""} onClick={handleUndo}><CpIco src={IconList.draw.undo} /></Button>
                </div><div className={`draw-editor-tool draw-redo`}>
                    <Button variant={""} onClick={handleRedo}><CpIco src={IconList.draw.redo} /></Button>
                </div>

                {/*<div className={"flex-basis d-block d-md-none"}></div>*/}

                <div className={`vr ${widerscreen ? "d-none" : "d-block d-md-none"}`} />
                <hr className={"m-0 d-none d-md-block"} />

                <div className={`draw-editor-tool draw-download`}>
                    <Button variant={""} onClick={downloadDraw}><CpIco src={IconList.draw.download} /></Button>
                </div>
            </div>
        </div> : ((!doEdit && DLBtn) ?
            <div className={`draw-editor-toolbar ${widerscreen ? "wider-screen" : ""}`} style={{ alignSelf: 'flex-start' }}>
                <div className={`draw-editor-toolbar-container  ${widerscreen ? "d-grid flex-column align-items-center" : "d-inline-flex flex-row"} justify-content-center ${widerscreen ? "rounded" : "rounded"} px-3 py-2 gap-2 flex-nowrap mx-auto`}>
                    <div className={`draw-editor-tool draw-download`}>
                        <Button variant={""} onClick={downloadDraw}><CpIco src={IconList.draw.download} /></Button>
                    </div>
                </div>
            </div> : '')
        }</>;
    };

    // overlay bar
    const overlayBar = (dir, noEdit = false) => {
        const widerscreen = dir === 'h';
        const isH = selectedTool === 'highlight';
        const _brushSize = isH ? hbrushSize : brushSize;
        const _bcolor = isH ? hcolor : bcolor;
        return <div ref={toolContainer} className={`d-flex justify-content-center sticky-drawing-tool `}>
            <div className={`draw-editor-toolbar drawing-layer-toolbar mb-4 mb-md-3`}>
                <div className={`draw-editor-toolbar-container d-flex flex-row justify-content-center rounded px-3 py-2 gap-2 flex-wrap flex-md-nowrap mx-auto`}>
                    <div className={`draw-question`} onClick={(e) => { gotoQ && gotoQ(e, idx); }} style={{ cursor: 'pointer' }}>
                        <div className={"drawing-layer-question draw-image-option text-nowrap"}>
                            <div className={"mx-1 mx-md-2 my-md-1 rounded"}>
                                {t('q')}{QNo}
                            </div>
                        </div>
                    </div>
                    <div className={`vr d-block`} />
                    <div className={`draw-editor-tool draw-select ${selectedTool === "pencil" ? "active" : ""}`}
                        onClick={() => { handlePen("pencil") }}>
                        <Button variant={""} disabled={noEdit}>
                            <CpIco src={IconList.draw.pencil} />
                        </Button>
                    </div>
                    <div className={`draw-editor-tool draw-pencil  ${selectedTool === "highlight" ? "active" : ""}`}
                        onClick={() => { handlePen("highlight"); }}>
                        <Button variant={""} disabled={noEdit}>
                            <CpIco src={IconList.draw.highlight} />
                        </Button>
                    </div>
                    <div className={`draw-editor-tool draw-text  ${selectedTool === "straightLine" ? "active" : ""}`}
                        onClick={() => { handleLine("straightLine"); }}>
                        <Button variant={""} disabled={noEdit}>
                            <CpIco src={IconList.draw.straightLine} />
                        </Button>
                    </div>
                    <div className={`draw-editor-tool draw-eraser ${selected ? "selected-object-trash" : ""} ${selectedTool === "eraser" && !selected ? "active" : ""}`}
                        onClick={() => { handleEraser(); }}>
                        <Button variant={""} disabled={noEdit}>
                            <CpIco src={selected ? IconList.draw.trash : IconList.draw.eraser} />
                        </Button>
                    </div>
                    <div className={`vr d-none d-md-block`} />
                    <hr className={"flex-basis m-0 d-block d-md-none"} />
                    <div className={`draw-editor-tool draw-brush-width ${widerscreen ? "" : ""}`}
                        ref={colorPickerRef}>
                        <OverlayTrigger container={toolContainer} key={_brushSize} trigger="click"
                            placement={`${widerscreen ? "bottom" : "bottom"}`} overlay={Brushpopover()}
                            rootClose>
                            <Button variant={""} disabled={noEdit} className={"d-inline-flex align-items-center fs-8"}>
                                {getSizeName(_brushSize)}
                            </Button>
                        </OverlayTrigger>
                    </div>
                    <div className={`draw-editor-tool draw-color ${widerscreen ? "" : ""}`}
                        ref={colorPickerRef}>
                        <OverlayTrigger container={toolContainer} trigger="click" placement={`${widerscreen ? "bottom" : "bottom"}`}
                            overlay={Colorpopover()} rootClose>
                            <Button variant={""} disabled={noEdit} className={"d-inline-flex align-items-center"}>
                                <span className={"color-pantone-container position-relative overflow-hidden"}>
                                    <span className={"color-pantone position-absolute top-0 start-0 w-100 h-100"}
                                        style={{ background: getRGBA(_bcolor.rgb) }}></span>
                                </span>
                            </Button>
                        </OverlayTrigger>
                    </div>

                    <div className={`vr d-block`} />
                    <div className={`draw-editor-tool draw-undo`}>
                        <Button variant={""} onClick={handleUndo}>
                            <CpIco src={IconList.draw.undo} />
                        </Button>
                    </div>
                    <div className={`draw-editor-tool draw-redo`}>
                        <Button variant={""} disabled={noEdit} onClick={handleRedo}>
                            <CpIco src={IconList.draw.redo} />
                        </Button>
                    </div>

                    <div className={`vr d-block`} />
                    <div className={`draw-editor-tool draw-quit`}>
                        <Button variant={""} onClick={handleCloseBar}>
                            <CpIco src={IconList.draw.quit} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    };

    const handleARImgLoaded = (e) => {
        UI.stopEvent(e);
        setBGInfo({ loaded: true, width: e.target.width, height: e.target.height });
    };
    const exStyle = BGInfo.loaded ? (LDMode === 'ftw' ? { width: '100%' }
        : { width: BGInfo.width + 'px', height: BGInfo.height + 'px' })
        : {};

    const mouseUp = cv => { if (cv) setSelected(cavHasSelected(cv)); };
    const drawingCanvas = (isOverLay = 0) => {
        const hasQtnBG = BGImgSrc ? 1 : 0;
        const exStyle1 = (LDMode === 'ftw') ? { width: '100%' } : {};
        const exStyle2 = isOverLay ? {
            backgroundColor: 'transparent', boxShadow: 'none',
            position: 'absolute', top: 0, left: 0
        } : {};
        //return <div className='qtnOPDoDrawArea' style={{ width: (LDMode === 'ftw' ? '100%' : 'auto'), ...exStyle2 }}>            
        //crossOrigin='anonymous'
        //<div id={'ctr' + id} tabIndex={-1} onBlur={(e)=>canvasLostFocus(e)}
        return <><div className='qtnOPDoDrawArea' style={{ ...exStyle1, ...exStyle2 }}>
            {hasQtnBG
                ? <div style={{ margin: 'auto', width: (LDMode === 'ftw' ? '100%' : 'auto') }}>
                    <div id={'ctr' + id} className={'DrawCanvasContainer ' + ((showResult || (!doEdit)) ? 'unclickable' : '')}
                        style={LDMode === 'fth' ? exStyle : {}}>
                        {BGImgSrc ? <img style={{ display: "none" }} src={BGImgSrc} onLoad={(e) => handleARImgLoaded(e)} alt='draw bg image 1' /> : ''}
                        {BGImgSrc ? <img className='LDARImg' src={BGImgSrc} style={LDMode === 'ftw' ? exStyle : {}} alt='draw bg image 2' /> : ''}
                        {BGInfo.loaded ? <DrawingCanvas id={id} setCanvas={setCanvas} updateResp={updateResp} mouseUp={mouseUp}
                            hasQtnBG={hasQtnBG} BGImgSrc={BGImgSrc} /> : ''}
                    </div>
                </div>
                : <div id={'ctr' + id} className={'DrawCanvasContainer ' + ((showResult || (!doEdit)) ? 'unclickable' : '')}>
                    <DrawingCanvas id={id} setCanvas={setCanvas} updateResp={updateResp} mouseUp={mouseUp} hasQtnBG={hasQtnBG}
                        isOverLay={isOverLay} cvOnFocus={cvOnFocus} cvOnBlur={cvOnBlur} />
                </div>
            }
            </div>
            </>
    };

    if (isOverLay) {
        return <>
            <div id={'drawOverlay' + idx} className={PVMode === 0 ? "drawOverlaySticky" : ""}>
                {overlayBar('v', doDisable)}
            </div>
            {isScriptReady ? drawingCanvas(isOverLay) : <div>{'loading'}</div>}
        </>
    };

    return isScriptReady ? <div className='qtnOPDoDrawCont'>
        {drawingCanvas()}
        {!newDrawBar ? drawBtnBar('v') : ''}
        {!newDrawBar ? drawBtnBar('h') : ''}
        {newDrawBar ? enhanceBar('v') : ''}
        {!newDrawBar ? (doEdit ? '' : <div className={'DrawCanvasBtnDL'}>
            {drawBtn('general/download', downloadDraw, { width: '25px', height: '25px' })}</div>) : ''}
    </div> : <div>{'loading'}</div>;
};
export default DrawComponentV5;

const verticalLine = h => <div style={{ border: '1px solid lightgrey', width: '1px', height: h + 'px' }}></div>
const horizontalLine = w => <div style={{ border: '1px solid lightgrey', width: w + 'px', height: '1px' }}></div>

const brushOptions = (opts, setBrushSize) => {
    return <div className='flexColStartFit' style={{ padding: '5px' }}>{opts.map((opt, ii) => {
        const ss = opt + 'px';
        return <div key={'bo' + ii} className='flexColCenter clickable' onClick={() => setBrushSize(opt)}
            style={{ minWidth: '40px', minHeight: '30px' }}>
            <div style={{ backgroundColor: 'black', borderRadius: '50%', minWidth: ss, minHeight: ss }}></div>
        </div>
    })}</div>
};

const getLineIconDesktop = lineType => //iconSelect 
    (lineType === "straightLine") ? IconList.draw.group.straightLineDesktop
        : (lineType === "dashLine") ? IconList.draw.group.dashLineDesktop
            : (lineType === "arrow") ? IconList.draw.group.arrowDesktop
                : IconList.draw.group.straightLineDesktop;

const getLineIconMobile = lineType => //iconSelect
    (lineType === "straightLine") ? IconList.draw.group.straightLineMobile
        : (lineType === "dashLine") ? IconList.draw.group.dashLineMobile
            : (lineType === "arrow") ? IconList.draw.group.arrowMobile
                : IconList.draw.group.straightLineMobile;

const computeArrow = (options) => { // poc drawingtool.tsx
    let { fromX, toX, fromY, toY, len = 15 } = options;

    const angle = Math.atan2(toY - fromY, toX - fromX);
    //console.log('arrow size:', len);
    const headlen = len; // arrow head size

    // bring the line end back some to account for arrow head.
    toX = toX - headlen * Math.cos(angle);
    toY = toY - headlen * Math.sin(angle);

    // calculate the points.
    const points = [
        { x: fromX, y: fromY }, // start point
        {
            x: fromX - (headlen / 4) * Math.cos(angle - Math.PI / 2),
            y: fromY - (headlen / 4) * Math.sin(angle - Math.PI / 2)
        },
        {
            x: toX - (headlen / 4) * Math.cos(angle - Math.PI / 2),
            y: toY - (headlen / 4) * Math.sin(angle - Math.PI / 2)
        },
        {
            x: toX - headlen * Math.cos(angle - Math.PI / 2),
            y: toY - headlen * Math.sin(angle - Math.PI / 2)
        },
        { x: toX + headlen * Math.cos(angle), y: toY + headlen * Math.sin(angle) }, // tip
        {
            x: toX - headlen * Math.cos(angle + Math.PI / 2),
            y: toY - headlen * Math.sin(angle + Math.PI / 2)
        },
        {
            x: toX - (headlen / 4) * Math.cos(angle + Math.PI / 2),
            y: toY - (headlen / 4) * Math.sin(angle + Math.PI / 2)
        },
        {
            x: fromX - (headlen / 4) * Math.cos(angle + Math.PI / 2),
            y: fromY - (headlen / 4) * Math.sin(angle + Math.PI / 2)
        },
        { x: fromX, y: fromY }
    ];

    return {
        points,
        tip: points[4],
        end: points[0]
    };
};

/*
export const txtByteSize = (str) => new Blob([str], { type: "text/html" }).size;
export const jsonByteSize = (obj) => new Blob([JSON.stringify(obj, null, 2)], { type: "application/json" }).size;
*/

