import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as SQT from '../../consts/ATSysQType';
import * as UI from '../../libs/libUI';
import * as MCAC from '../../consts/ATQtnAnsTypeMCQ';
import { preJS, autoId, deepCopy } from '../AppUtil';
import QEditorReadOnly from '../components/ckeditor5/CpQEditorReadOnly';
import CpATPullDownMenu from '../components/CpATPullDownMenu';
import CpATImage from '../components/CpATImage';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import Ckeditor5Base from '../components/ckeditor5/Ckeditor5Base';
import {  toAry, toObj, toStr, toInt, objVals } from '../../libs/libType';
import { humanFileSize } from '../../libs/libFormat';
import { asyncDL, asyncGetDraw, isLocalMedia,  } from '../utils/useMediaCache';
import FileExtensionIcon from '../components/CpFileExtensionIcon';
import { prefixURL } from '../ATExerEdit/ViewExCommon';
import {useDropzone} from 'react-dropzone';
import { fileExt } from '../../libs/libFormat';
import { __MediaExts, __MediaTypes, validToUpload, } from '../../consts/MimeTypes';
import { useUILang } from '../utils/useUILang';
import { __RESP_TXT, __RESP_DRAW, __RESP_FILE, __RESP_IMAGE, __RESP_URL } from '../../consts/ATSysQType';
import { ReduxBind } from '../../saga/ReduxState';
import { txtByteSize } from '../../libs/libType';
import { popAlert } from '../components/CpPopup';
import { debugMode } from '../../saga/ReduxState';
import { debugTrimDraw } from '../../consts/AValidateWork';
import { _ansClass } from '../../AppExPFUser/EPExercise/constQtnTemplate';
import DrawComponentV5 from './CpQDoDrawToolsV5';
import { useResize } from './CpATQtnSingle';
import { validDrawSize, byteToMB } from '../utils/utilQtnAns';

const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    };
};

const resetqCorrect = (arr, shuffle=false,resp=undefined) => {
    const result = deepCopy(arr);
    const checkChooseGeneral = (reultArr, cii) => {
        return reultArr.findIndex((x)=>{return x.includes(","+cii)})>=0;
    };    
    const stResult = resp?.qresp || undefined;
    const sequence = resp?.sequence || undefined;

    result.forEach((ans,ii)=>{
        if (stResult && checkChooseGeneral(stResult,ii)) {ans.correct=1}
        else ans.correct = 0;
        ans.orgIdx=ii;
    });

    if (shuffle) {
        if (sequence) {
            const tmp = [];
            sequence.forEach(si=>{tmp.push(result[si]);});
            return tmp;
        } else {
            shuffleArray(result);
        }
    };
    return result;
};

const extractqChoose = (arr) => {
    const qresp = [];
    const sequence = [];
    arr.forEach((ans,ii)=> {
        if (ans.correct) qresp.push(ii+","+ans.orgIdx);
        sequence.push(ans.orgIdx);
    });
    if (qresp.length > 0) return {qresp, sequence}
    else return {sequence};
};


const CpQDoOPE = (props) => {
    const { fullQ, Q, mediaDLs, getMediaDLs} = props;
    const { containerClass, editable, preview, asmView, asmEdit, doEdit, isTeacher, isStudent, needDrawDeb=0,
        showResult, showCorr, mini=0, setStudentResp, setTeacherResp, studentResp, teacherResp, resizeCB=0 } = props;
    const [ t ] = useUILang();

    const doFlag = preview || asmView || asmEdit;

    //After Inint Delay All Actions ===
    const [afterInit, setAfterInit] = useState(0);
    useEffect(()=>{ setAfterInit(1); },[]);

    const _resizeCB = (data) => {
        //console.log('cb op cksize', data );
        if (fullQ.SQType === SQT.__SYSQSubType_POL) return;
        resizeCB && resizeCB(data);
    };
    
    const ckContentRef = useRef(null);
    // don't remove useResize
    const ckSize = useResize(ckContentRef.current, _resizeCB);   

    //Basic Question Info
    const qData = Q?.qData || "";
    const myID = 'ansDo'+fullQ.QId;

    //console.log('studentResp', fullQ.QId, studentResp);
    //Resp Type
    const {respText, respDrawing, respImage, respFile, respURL} = toObj(fullQ);
    const initRT = studentResp?.respType ||
        (respText?__RESP_TXT:
        respDrawing?__RESP_DRAW:
        respImage?__RESP_IMAGE:
        respFile?__RESP_FILE:
        respURL?__RESP_URL:'');
    const [respType, setRespType] = useState(initRT);

    //For Polling Opts Type 
    const ansChoice = fullQ.ansChoice? fullQ.ansChoice: MCAC.__MCAC_ACL;
    const shuffleAnswer = ((ansChoice !== MCAC.__MCAC_ACB) && (fullQ.shuffleAnswer? fullQ.shuffleAnswer: 0));
    const ansClass = ansChoice ? _ansClass[ansChoice] : _ansClass['ACG'];
    const orgqAnswers = deepCopy(Q?.qAnswers||[]);
    const [myAnsArr, setMyAnsArr] = useState(doFlag? resetqCorrect(orgqAnswers, shuffleAnswer, studentResp): orgqAnswers);

    // Feed Back Student Resp  =======
    useEffect(() => { // Feed Back Student Resp Type Check
        if (afterInit && isStudent && setStudentResp && !showResult) {
            if (studentResp) {
                //console.log('called ?!');
                delete studentResp['qresp'];
                delete studentResp['qrespS3Id'];
            };
            setStudentResp({...studentResp, respType});
        };
    },[respType]);

    useEffect(() => { // Feed Back Student Resp MC Choose
        if (afterInit && (fullQ.SQType === SQT.__SYSQSubType_POL) && isStudent && setStudentResp && !showResult) {
            const extInfo = extractqChoose(myAnsArr);
            setStudentResp({...studentResp, ...extInfo});
        };
    },[ myAnsArr ]);

    const setStResponse = (t, v) => { //Return Resp[idx] to Work
        if (afterInit && isStudent && setStudentResp) {
            //console.log('doOPE response !!!', v);
            const drawS3Id = (t === __RESP_DRAW)? { qrespS3Id:"" }:{};
            setStudentResp({...studentResp, respType:t, qresp:v, ...drawS3Id});
        };
    };
    const setTeResponse = (t, v) => { //Return Resp[idx] to Mark
        if (afterInit && isTeacher && setTeacherResp) {
            const drawS3Id = (t === __RESP_DRAW)? { qrespS3Id:"" }:{};
            setTeacherResp({...teacherResp, respType:t, qresp:v, ...drawS3Id});
        };
    };

    const respOpts = () => {
        const optsArr = [];
//at-setting.response-type.text uploadfile image drawing hyperlink
        respText && optsArr.push({id:__RESP_TXT,txt:t('at-setting.response-type.text'),icon:'inputType/plainTextEditor'});
        respDrawing && optsArr.push({id:__RESP_DRAW,txt:t('at-setting.response-type.drawing'),icon:'inputType/drawingEditor'});
        respImage && optsArr.push({id:__RESP_IMAGE,txt:t('image'),icon:'inputType/imageEditor'});
        respFile && optsArr.push({id:__RESP_FILE,txt:t('at-setting.response-type.uploadfile'),icon:'inputType/uploadFileEditor'});
        respURL && optsArr.push({id:__RESP_URL,txt:t('at-setting.response-type.hyperlink'),icon:'inputType/hyperlinkEditor'});

        return optsArr.map((opt, idx)=>{
            return {id: opt.id, 
                jsx: (sel = false) => <div key={idx} className='flexRowStartFit' style={{width:'auto'}}>
                    {svgIcon2(opt.icon, sel? "#31279d": "black", { width: "16px", height:"16px" }, sel? "#31279d": "black")}
                    <div style={{margin:'0 5px 0 5px',whiteSpace:'nowrap'}}> {opt.txt}</div>
                </div>
            };
        });
    };
    
    const miniStyle = mini?{gridTemplateColumns: '100%'}:{};
    const contentDisplayOnly = showResult || asmView || isTeacher;

    // Feed Back Qtn Edit Set Option Correct
    const ddClick = (e, ii) => {
        UI.stopEvent(e);
        if (!doEdit) return;
        if (fullQ.multiAnswer){
            myAnsArr[ii].correct = myAnsArr[ii].correct? 0: 1;
        }else{
            myAnsArr.forEach((ans, jj) => { ans.correct = ((ii === jj)? 1: 0) });
        } 
        setMyAnsArr([...myAnsArr]);
    };    

    const edProps = {mediaDLs, getMediaDLs };
    const mcOpt = (obj, ii) => {
        let exClass = (obj.correct? " Sel": "") + (doFlag? " preview": "");
        if (showResult) {
            const stResp = studentResp?.qresp;
            const choose = stResp && stResp.findIndex( x => x.includes(ii+",")) >= 0;
            if (choose) exClass = " Sel preview"; // keep sel color for polling option
        };

        if (ansChoice === MCAC.__MCAC_ACB) {
            return <div {...{key: "AC" + ii, className: "f32 bold DDQtnLabel" + exClass, onClick: (e => ddClick(e, ii)) }}>
                {String.fromCharCode(65 + ii)}
            </div>;
        };
        return <><table key={"DDtb" + ii} className={"DDcontentTbl" + (editable ? "" : " unclickable")} onClick={(e) => ddClick(e, ii)}>
            <tbody><tr>
                <td className={"DDtd1"+exClass} onClick={(e) => ddClick(e, ii)}>
                    <div className="flexColCenter DDCharHead">{String.fromCharCode(65 + ii)}</div>
                </td>
                <td className={"DDtd2"+exClass} style={{borderLeft:'none'}}><div className={"DDQtnEditRow"}>
                    {obj.type===MCAC.__MCAT_ATI
                        ?<CpATImage id={"img"+ii} media={obj.data} editable={false} {...edProps}/>
                        :<QEditorReadOnly {...props} data={obj.data} {...edProps}/>}
                </div></td>
            </tr></tbody>
        </table></>;
    };

    return <div className={containerClass}>
        <div className='tabDoQContent2' id="mathDisplay">
            <div id={myID} ref={ckContentRef} className='tabDoQContent3' style={{marginBottom:'30px'}}>
                <QEditorReadOnly {...props} data={qData} {...edProps}/>
            </div>
            {(fullQ.SQType !== SQT.__SYSQSubType_POL) && <div className='qtnOPDoContainer'>
                {(fullQ.SQType === SQT.__SYSQSubType_OEG) && <div className='flexRowStart f18' style={{marginBottom:'10px'}}>
                    <div style={{marginRight:'5px'}}>{contentDisplayOnly?t('openended.preview.student_response')
                        :t('openended.preview.choose_your_type_of_response')}</div>
                    {!contentDisplayOnly && <CpATPullDownMenu sel={respType} setSel={setRespType} opts={respOpts()} right={false}
                        containerStyle={{ width:"auto", borderColor:"#31279d" }}
                        arrowColor={"#31279d"} menuStyle={{ justifyContent: "flex-end", fontSize:"17px" }} 
                        headerStyle={{ width:"auto", justifyContent: "center",borderColor:"#31279d",
                        backgroundColor:"white", color:"#31279d"}} />}
                </div>}
                {(doEdit||doFlag)?<RespStudentContent {...props} {...{respType}} setResponse={isTeacher?setTeResponse:setStResponse}/>:''}
            </div>}
            {(fullQ.SQType === SQT.__SYSQSubType_POL) && <div className={"DDTopContainer " + ansClass} style={miniStyle}>
                {myAnsArr.map((obj, ii) => {
                    return <div key={"qtdo" + ii} className={"DDoptionContainer " + (obj.correct?" Sel":"")}>
                        {mcOpt(obj, ii)}
                    </div>
                })}
            </div>}            
        </div>
    </div>;
};
export default CpQDoOPE;

const RespStudentContent = (props => {
    const {showResult, isTeacher, isStudent, doEdit, respType, studentResp, teacherResp, setResponse, idx, showEn,
        setOnAddMedia, mediaDLs, addLocalMedias, getMediaDLs, dispatch, preview, asmView, needDrawDeb=0 } = props;

// Common 
    const [t] = useUILang(); 

    const fullQ = toObj(props.fullQ);
    const stResp = studentResp?.qresp;

//For Text Input Resp ====== ====== ====== ======
const [countInfo, setCountInfo] = useState(toObj(stResp?.wcInfo));
useEffect(()=>{
    if ((respType === __RESP_TXT) && ((countInfo?.chars) > 0 || (countInfo?.words) > 0)) {
        //console.log('set countInfo resp');
        setResponse(__RESP_TXT, {...toObj(stResp), wcInfo:{...countInfo}});
    };
},[countInfo]);

// For Drawing Resp Type ====== ====== ====== ======
    const isDraw = (respType === __RESP_DRAW);
    const isTeacherDraw = (isTeacher || (isStudent && showResult)); //is teacher otherwis student

    //const noRespYet = isTeacherDraw?(!isTeacherDraw.hasOwnProperty('qresp')):(!studentResp.hasOwnProperty('qresp'));
    const drawDataID = toStr(isTeacherDraw? (teacherResp?.qrespS3Id): (studentResp?.qrespS3Id));
    const bgDataID = toStr(isTeacherDraw? (studentResp?.qrespS3Id): '');

    const [drawResponse, setDrawResponse] = useState(null);
    const [drawData, setDrawData] = useState(isTeacherDraw? (teacherResp?.qresp): (studentResp?.qresp)); //fabric Data
    const [bgData, setBgData] = useState(isTeacherDraw? (studentResp?.qresp): null);  //fabric Data

    const [doDrawReset, setDoDrawReset] = useState(0); //send Reset Command to Draw
    const [drawCanvas, setDrawCanvas] = useState(0); // hold the canvas

    const waitBG = isTeacherDraw? (studentResp?.qrespS3Id): 0;
    const waitDraw = isTeacherDraw? (teacherResp?.qrespS3Id): (studentResp?.qrespS3Id);

    const loadDrawById = async () => { 
        if (!(isDraw && drawDataID) || drawData) return; 
        const fgDraw = await asyncGetDraw(dispatch, getMediaDLs, drawDataID); // ddata is  
        console.log('effect loadDrawById() ', {isDraw, bgDataID, fgDraw: debugTrimDraw(fgDraw)});
        /*** rollback ***/
        drawInit.current = true;
        /*** rollback ***/
        setDrawData(fgDraw);     
        //teacherResp.qresp = ddata; // only for init Canvas, No need to feed back resp 
        //studentResp.qresp = ddata; // only for init Canvas, No need to feed back
    };
    useEffect(()=>{ loadDrawById(); },[drawDataID]);

    const loadBgById = async () => {
        if (!(isDraw && bgDataID) || bgData) return; 
        const bgDraw = await asyncGetDraw(dispatch, getMediaDLs, bgDataID);
        console.log('effect loadBgById() ', {isDraw, bgDataID, bgDraw: debugTrimDraw(bgDraw)});
        setBgData(bgDraw);         
        //studentResp.qresp = ddata; // only for init Canvas, No need to feed back
    };
    useEffect(()=>{ loadBgById(); },[bgDataID]);
    //useEffect(()=>{ if (drawResp) setResponse(__RESP_DRAW, drawResp); }, [drawResp]); // write data outside
    /*** rollback ***/
    const drawInit = useRef(waitDraw);
    useEffect( () => { if (isDraw && drawData) {
        if (!drawInit.current) {
            setResponse(__RESP_DRAW, drawData);
        } else { 
            drawInit.current = false; 
        }
    }; }, [drawData]);
    useEffect(()=>{ // need rollback one step, don't update draw data back directly
        if (drawResponse) {
            //console.log('drawResponse()', {isDraw, preview, drawCanvas, isTeacher, draw:debugTrimDraw(drawResponse), });
            if (isDraw && drawResponse) {
                const [isValid, drawSize] = (!preview && drawCanvas)? validDrawSize(drawCanvas, isTeacher?'te':'st'): [1,0];
                if (isValid) { 
                    //setResponse(__RESP_DRAW, drawResponse); //setDrawData(drawResponse); No Nedd to Update State, Just Ret To Resp
                    /*** rollback ***/
                    setDrawData(drawResponse);
                    setDoDrawReset(0); //no need to NOT trigger Reset?
                    /*** rollback ***/
                } else { 
                    setDoDrawReset(1);
                    popAlert(dispatch, 1, t(isTeacher? 'warning.warning_drawing_total_size_teacher'
                    : 'warning.warning_drawing_total_size_student').replace("%s", byteToMB(drawSize)));
                };    
            };            
        }
    },[drawResponse]);    
    //const _setDrawResponse = v => { if (v) setDrawResponse(v); };
    /*** rollback ***/

// UI Component Rendering 
    if (respType === __RESP_TXT){// ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ======
        if (doEdit) {
            const setText = value => setResponse(__RESP_TXT, {...toObj(stResp), value});
            const wordLimit = toInt(fullQ.wordLimit);
            const ckImageConfig = { setOnAddMedia, updateMediaID: arr => { return getMediaDLs([...arr]); }, };
            return <React.Fragment key='respCK'><Ckeditor5Base {...props} editorType={"inputResponse"} 
                data={stResp?.value||''} setData={(v)=>setText(v)} needDebounce={true}
                enable={true} debug={false} showStatus={false} ckImageConfig={ckImageConfig}
                setCountInfo={setCountInfo}/>
                {wordCountDisplay(t, showEn, countInfo, wordLimit)}
            </React.Fragment>
        } else if (showResult || isTeacher || asmView) {
            return <React.Fragment key='respCK'><div className='tabDoQContent3'>
                <QEditorReadOnly {...props} data={stResp?.value||'<p/>'}/>                        
            </div></React.Fragment>
        } else return '';

    } else if(respType === __RESP_DRAW){ // ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ======
        let _drawData, _bgData;
        if (isTeacher || (isStudent && showResult)) {
            _drawData = teacherResp?.qresp || drawData;
            _bgData = studentResp?.qresp || bgData;
        } else {
            _drawData = studentResp?.qresp || drawData;
            _bgData = undefined;
        };

        const {respType, qrespS3Id, qresp} = toObj(studentResp); 
        const __qresp = debugTrimDraw(qresp);
        const __drawData = debugTrimDraw(drawData);
        //const __bgData = debugTrimDraw(bgData);

        
        const drawDataReady = isTeacherDraw
            ?((waitDraw? drawData: 1) && (waitBG? bgData: 1)) //teacher Wait for bg + draw
            : (waitDraw? (drawData):1); //student Wait for draw
        
        //console.log('drawDataReady',{drawDataReady, waitDraw, __drawData, waitBG, __bgData} );

        return <React.Fragment key='respDraw'>
            {preJS({respType, qrespS3Id, __qresp, isDraw, __drawData}, 3)}
            {drawDataReady? <DrawComponentV5 {...{dispatch, isTeacher, showResult, preview, doEdit:(doEdit||isTeacher), idx, setResponse: setDrawResponse,
            drawData: _drawData, bgData:_bgData, doReset:doDrawReset, setDoReset:setDoDrawReset, setParentCanvas: setDrawCanvas, ignoreRemoveBG:1, needDrawDeb }}/>:'loading'}
            </React.Fragment>;

    }else if((respType === __RESP_IMAGE) || (respType === __RESP_FILE) ){  // ====== ====== ====== ====== ====== ====== 
        return <StudentRespFile {...{ dispatch, respType, stResp, fullQ, doEdit, preview, setResponse,
            setOnAddMedia, mediaDLs, addLocalMedias, getMediaDLs,}} />

    }else if(respType === __RESP_URL){  // ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== 
        const url = stResp?.URL||'';
        const caption = stResp?.caption||'';
        if (doEdit) {
            const setURL = (key, val) => setResponse(__RESP_URL, {...toObj(stResp), [key]: val});
            return <div key={'respHlink'} className='flexColStartFit'>
                <div className='flexRowStart f16'>URL</div>
                {UI.EpInputTxt0(url, v => setURL('URL', v), 'URL', '', {border:'1px solid lightgray', borderRadius:'6px', marginBottom:'5px', width:'100%'})}
                <div className='flexRowStart f16'>Display Text</div>
                {UI.EpInputTxt0(caption, v =>setURL('caption', v), 'caption', '', {border:'1px solid lightgray',borderRadius:'6px',width:'100%'})}
            </div>
        } else {  // ====== ====== ======  
            return <div key={'respHlink'} className='qtnOPDoURL f18 clickable' onClick={(e)=>openLink(e, prefixURL(url))}> 
                {svgIcon2('file/link','black',{width:'60px',height:'30px',margin:'10px 20x 10px 10px'})}
                <div className='flexColStart f18'>
                    {caption?<div className='flexRowStart'>{caption}</div>:''}
                    {url?<div className='flexRowStart'>{url}</div>:''}
                </div>
            </div>;
        }
    }// ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ====== ======
    return <div>No response component for respType: {toStr(respType)}</div>;
});

const StudentRespFile = ReduxBind(props => {
    const { dispatch, respType, stResp, fullQ, doEdit, preview, setResponse } = props;
    const { _MaxMediaSize } = toObj(props.settings());
    const { mediaDLs, addLocalMedias, getMediaDLs, } = props;  

    const maxFile = fullQ.maxFile || 1;
    const respImg = respType === __RESP_IMAGE;
    const ValidExts = respImg? __MediaTypes['image']: __MediaExts;

    const LL = toAry(stResp);

    const [t] = useUILang(); 

    const deleteEle = i => {
        LL.splice(i, 1); 
        setResponse(respType, [...LL]); 
    };

    const onDrop = async (addFiles) => {
        const free = (maxFile - LL.length);
        let anyError = 0;     
        const validFs = (free > 0) && toAry(addFiles).filter(f => {
            const hasError = UpFileErrs(f, ValidExts, _MaxMediaSize);
            if (hasError) anyError = 1;
            return !hasError;
        }).slice(0, free);
        if (anyError) popAlert(dispatch, 1, t(respImg?'warning.warning_image_file':'warning.warning_all_files'));
        if(!(validFs?.length)) return;
        const newMs = addLocalMedias(validFs, 0);
        
        setResponse(respType, [...LL, ...objVals(newMs)]); 
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});    

    const edProps = {dispatch, mediaDLs, getMediaDLs};
    const downloadFile = (e, mediaId) => { UI.stopEvent(e); asyncDL(props.dispatch, getMediaDLs, mediaId); };
    const ele = respImg? imgBlock: fileBlock;
    const rList = (arr, rtype) => {
        return <div key={'rlist'+rtype} className='qtnOPDoRListContainer'>
            {arr.map((obj, i)=>{
                return <div key={'rList'+i} className='qtnOPDoRListItem '>
                    {ele(obj, i, edProps, preview, downloadFile)}
                    {doEdit? <div className="qtnOPDoRListItemTrash">
                        {iconButton("", "general/trash", "#749ab3", "transparent",() => { deleteEle(i) }, 
                        true, { transform: "scale(1)", width: "20px" }, { width: "30px", height: "30px"})}
                    </div>:''}                    
                </div>;
            })}
        </div>;
    };
    const showAlertMsg = () => {
        const msg = t(respImg?'warning.warning_upload_total_size_student':'warning.warning_upload_total_size_student')
        .replace("%s", LL.length).replace("%s", maxFile);
        popAlert(dispatch, 1, msg);
    };
    const result = [];
    if (doEdit) {
        const canAdd = (maxFile - LL.length) > 0;
        const xx = toAry(stResp);
        const icon = respImg? 'inputType/imageEditor':'inputType/uploadFileEditor';
        const msg1 = respImg? t('drop.image.message'):t('drop.message');
        const msg2 = respImg? 'Supported file formats: '+__MediaTypes['image']:'Supported file formats: '+__MediaTypes['office'];
        const msg3 = respImg? '':__MediaTypes['video']+','+__MediaTypes['audio']+','+__MediaTypes['image']+','+__MediaTypes['pdf'];
        result.push(<div key={'respMedia'} className='qtnOPDoUploadBorder clickable usel '
            {...(canAdd?getRootProps():{onClick:()=>{showAlertMsg()}})}>
            <input {...getInputProps()}/>
            {svgIcon2(icon,'#31279d',{transform:'scale(2)'})}
            <div className='f18' style={{fontWeight:500,margin:'20px 0 10px 0'}}>{isDragActive?'Drop the files here...':msg1}</div>
            <div className='f12' style={{color:'#c1c0e1'}}>{msg2}</div>
            {respImg? '': <div className='f12' style={{color:'#c1c0e1'}}>{msg3}</div>}
            <div className='f12' style={{color:'#c1c0e1'}}>Max. no. of files: {xx.length +' / '+ maxFile}</div>
        </div>)
    };
    result.push(rList(LL, respType));
    return result;
});

const langCount = (showEn, countInfo) => {return toInt(showEn? countInfo?.words: countInfo?.chars);}
const wcColor = (word, wordLimit) => {return ((word <= wordLimit) || (!wordLimit)) ? '#b4b4b4':'red'};    
const wordCountDisplay = (t, showEn, countInfo, wordLimit) => {
    const word = langCount(showEn, countInfo); 
    return <div className='flexRowEnd'>{t('word-count')+': '}<span style={{color:wcColor(word, wordLimit), marginLeft:'5px'}}>
    {word}</span>{wordLimit?('/'+wordLimit):''}</div>
};

const openLink = (e, url) => {
    UI.stopEvent(e);
    if (url) { window.open(url, "_blank")}
};

export const UpFileErrs = (f, ValidExts, _MaxMediaSize) => {
    if(!f){ 
        return 'missing File';
    }else if(!ValidExts.includes(toStr(fileExt(f.name)).trim().toLowerCase())){
        return 'Invalid File Type';
    }
    return validToUpload(f, 0, _MaxMediaSize);
}; 

const URL = window.URL || window.webkitURL;
const imgBlock = (obj, ii, edProps, preview, downloadFile) => {
    return <CpATImage id={"img"+ii} {...edProps} media={{oupid:obj?.mediaId}} editable={false} allowDownload={true}/>;
};

const fileBlock = (obj, ii, edProps, preview, downloadFile) => {
    const {mediaId, name, ext, size} = obj;
    return <div key={'fb'+ii} className='flexRowStart clickable' onClick={ e => downloadFile(e, mediaId) }>
        <FileExtensionIcon name={ext} myStyle={{width: '30px', minWidth: '30px'}}/>
        <div className='flexColStartFit f14' style={{marginLeft:'5px', wordBreak: 'break-word'}}>
            <div className='flexRowStartFit' style={{wordWrap:'break-word'}}>{name}</div>
            <div>{humanFileSize(size)}</div>
            {preJS({mediaId})}
        </div>
    </div>;
};
