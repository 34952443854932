import React, {useMemo} from "react";
import { lang2t, langIsEn, useUILang, validLang } from "../../AppExPf/utils/useUILang";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { EPUITime3 } from "../_components/CpEpTabHead";
import CpIco, { CpLangIco } from "../_components/CpIco";
import { aryLen, maxVal, objEntries, toAry, toInt, toObj, toPercent1, toStr } from "../../libs/libType";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { ReduxBind } from "../../saga/ReduxState";
import { getTeacherName } from "../../AppExPf/ATExerEdit/TabExerContent";
import { _AST_IN_PROGRESS, _AST_PENDING, _AST_PUBLISHED, _AST_WAIT_RESULT, 
    _WST_SUBMIT, _WST_RESUME, _ARS_CLOSE_RESET, 
    _MST_MARKED} from "../../consts/ATConstsAssignment";
import CpActionButton from "../_components/CpActionButton";
import { stopEvent } from "../../libs/libUI";
import { _pathAssign } from "../../consts/ATConstReact";
import { BtnPopDev, preJS } from "../../AppExPf/AppUtil";
import { exerHasMark } from "./Tags/TagMarkingTag";
import { debugMode } from "../../saga/ReduxState";
import { getT2bEnd, getT2bSrt } from "./CpAssignmentList";
import { daysDiff_2min, timeStampNowGMT } from "../../libs/libTime";

import { TagStuProgressTag } from "./Tags/TagStuProgressTag";
import TagWorkSubmitTag from "./Tags/TagWorkSubmitTag";
import { TagCountdownTag } from "./Tags/TagCountdownTag";

import { showScore } from "../EPAsmWork/utilAsmWork";

import { expfRoles } from "../../saga/auth.saga";
import { getTransMap } from "../../libs/libTransMap";
import { splitSMId } from "../../consts/ATValidateMetaSet";
import CpMJX from "../_components/CpMJX";
import { asmIsReme } from "../../consts/EpAssigmentErr";
import { TagRemeLvs, TagStuRemePass } from "../../AppExPf/ATExerEdit/TagRemeLv";
import { toUniIdAry } from "../../consts/ATValidate";
import { meta2Name } from "../_components/CpEpTypeMSetTrees";

const CardStudentAssignmentCard = ReduxBind(props => {    //base on poc StudentAssignmentCard
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const [isThr, isStt, uRole ] = expfRoles(props);
    const myUId = props.userId();
    
    const [ cache2d, useGetCache ] = useCaches(props);
    const {tagMSets, useTagMSets } = props;

    const {clickAsm, clickAsmRpt, clickAsmReme} = props;

    const asm = toObj(props.asm);
    const {mark, work:w, Exer:ex, SRStat, ..._asm} = asm;
    const work = toObj(w);

    const {hasReme, hasFailReme } = toObj(SRStat); 
    
    const {remeSMId, assignId} = toObj(asm);
    const isReme = asmIsReme(asm);
    const remeLvs = toAry(isReme && objEntries(asm.remeLvStus).filter(lu => toAry(lu[1]).includes(myUId)).map(lu => lu[0])); //fake for old bronken data
    const [remeMSId, remeMId ] = splitSMId(remeSMId);
    const mset = useTagMSets([remeMSId])[remeMSId];
    const meta = toObj(mset?.metas?.[remeMId]);

    //const aPub = (asm?.AState) === _AST_PUBLISHED;
    const wSub = (work?.WState) === _WST_SUBMIT;
    const Exer = toObj(ex);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);
    const {EQcnt, EScore} = Exer;
    
    const MStat = toObj(mark?.MStat);
    const stScore = toInt(MStat?.aScore)+ toInt(MStat?.mScore); //toInt(MStat?.stScore);
    const usrMark = showScore(asm, work)? stScore: 0;

    const EMark = (toInt(aScore) + toInt(mScore)) || toInt(EScore) ;
    
    const passValue = useMemo(() => stuPassValue(asm, myUId), [asm, myUId]);
    const usrPass = useMemo(() => stuPassAsm(asm, mark), [asm, mark]); 
    
    const metaName = meta2Name(meta, langIsEn(asm.ALang)); 
    const AName = lang2t(asm.ALang, Exer.ENameEn, Exer.ENameCt);
    const QCnt = toInt( (aQCnt + mQCnt) || EQcnt);

    const thrNames = useGetCache('teachers', '*'); 
    const thrName = getTeacherName(asm.userId, thrNames, ut, t);

    // with manual mark, can attempt once only
    const attTimes = (asm.attempt)?toInt(asm.attTimes):(hasM?1:t('unlimited'));
    const stAttempt = maxVal(0, toInt(work._attemp) - (wSub?0:1));
    const renderAttempt =  stAttempt + ' / ' + attTimes;
    //const today = timeStampNowGMT();
    //const daysRemain = daysDiff_2min(today, asm?.time2beEnd);
    //const hasDue = asm?.timeEnd;
    const timeLimit = (asm?.limitMin)?(asm?.minsToGo):'--';
    
    const remeInf = {isReme, remeLvs, wSub, usrPass,  metaName, remeSMId, remeMSId, remeMId};
//if (layout !== "column") {
    return <div id={assignId} className="CardStudentAssignmentCard">
        {debugMode()?<div className='flexRowStart'>
                <BtnPopDev txt='_asm' >{preJS(_asm, 3)}</BtnPopDev>
                <BtnPopDev txt='Exer' >{preJS(Exer, 3)}</BtnPopDev>
                <BtnPopDev txt='work' >{preJS(work, 3)}</BtnPopDev>
                <BtnPopDev txt='mark' >{preJS(mark, 3)}</BtnPopDev> 
                <BtnPopDev txt='remeInf' >{preJS({remeInf}, 3)}</BtnPopDev> 
                <BtnPopDev txt='SRStat' >{preJS({SRStat}, 3)}</BtnPopDev> 
                <BtnPopDev txt='meta' >{preJS({mset, meta}, 3)}</BtnPopDev> 
        </div>:''}
        <div id={assignId} className={"CardStudentAssignmentCard d-flex flex-column border rounded-light sequenceListAnimation"}>
            <div className={`d-flex px-3 justify-content-between align-items-center px-3 py-2 bg-dim-100`}>
            <span className={`d-flex flex-wrap gap-2`}>
                {/*<CpSubmitTag status={notAttempt?"notAttempted":((work?.WState ===_WST_SUBMIT)? "submitted": "not-submitted")}/>*/}
                {/*asmStateEnded(asm)? <CpExpireTag days={daysDiff(getT2bSrt(asm), getT2bEnd(asm))}/>: '' */} 
                {/*((work?.WState !==_WST_SUBMIT) && (asm?.timeEnd) )? <CpExpireTag days={daysRemain}/>: '' */}
                {/*getProgressTag(asm)}
            {/*<getProgressTag asm={asm}/>}
                {CpStuProgressTag(asm,t)}
                {getSubAttTag(asm)}
                {getExpTag(asm)}
                {drawAsmMarkTags(asm)*/}
                {isReme? <TagStuRemePass {...{t, fill:0, pass:usrPass}} />:''}
                {isReme? <TagRemeLvs {...{remeLvs}} />: ''}
                {isReme? '': <TagStuProgressTag AState={asm.AState} WState={work.WState} hasM={hasM}/>}
                {isReme? '':<TagWorkSubmitTag {...{asm, work}}/>}       
                {isReme? '':<TagCountdownTag {...{asm}}/>}
                {/*<TagAsmMarkingTag {...{asm}}/>*/}
            </span>
                <span className={`d-flex gap-2`}>
                  <GetStudentActionButton {...{asm, work, isReme, hasReme, usrPass, clickAsm, clickAsmRpt, clickAsmReme}} />
                </span>
            </div>
            <div className={`d-flex flex-row justify-content-between gap-2 w-100 px-3 py-2`}>
            <span className={"d-flex flex-column justify-content-between"}>
                <span className={"d-flex justify-content-center align-items-start rounded fs-4 bg-exercise-secondary-btn mt-1"} style={{width: "1.5rem", height: "1.5rem"}}>
                    {CpLangIco(langIsEn(asm.ALang))}
                </span>
                <span className={"d-flex justify-content-center align-items-start rounded fs-4 text-light mt-1"} style={{width: "1.5rem", height: "1.5rem"}}>
                </span>
            </span>
                <span className={"d-flex flex-column justify-content-between flex-grow-1"}>
                <span className={"mb-2"}>
                    <div className={"semi-bold d-flex justify-content-between align-items-start fs-6 mt-1 mb-2"}>
                        {isReme? <div className="flexRowCenterFit gap-2" style={{flexWrap:"wrap"}}>
                            {t("assignment-follow-up-exercise")} <span className="workFollowName2"><CpMJX>{metaName}</CpMJX></span>
                        </div>:                      
                        <span><CpMJX>{AName}</CpMJX></span>}
                    </div>
                    <div className={"d-flex justify-content-between align-items-end gap-3"}>
                        <span className={`d-flex flex-column gap-2`}>
                            <span className={`d-flex flex-column flex-md-row flex-wrap gap-2`}>
                                <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t("assignment-info-start")}:</span>
                                    <span>{getT2bSrt(asm)}</span>
                                </span>
                                <span className={"me-3 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t("assignment-info-end")}:</span>
                                    <span>{getT2bEnd(asm)}</span>
                                </span>
                                <span className={"me-3 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t("assignment-time-limit")}:</span>
                                    <span>{timeLimit}</span>
                                </span>
                                <span className={"d-flex align-items-center me-3 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t(getTransMap("assignment-assigned-by",isStt))}:</span>
                                    <span className={"d-flex align-items-center gap-2"}><CpIco src={IconList.general.avatar}/>
                                    <span>{thrName}</span></span>
                                </span>
                            </span>
                            <span className={`d-flex flex-column flex-md-row gap-2`}>
                                <span className={"d-flex flex-wrap justify-content-start align-items-center pe-1 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t("assignment-last-submission")}:</span>
                                    <span> {work?.dateSub? EPUITime3(work?.dateSub): '--'} </span>
                                </span>
                                <span className={"me-3 fs-8"}>
                                    <span className={"semi-bold pe-2"}>{t("assignment-no-of-attempts")}:</span>
                                    <span>{renderAttempt}</span>
                                </span>
                            </span>
                            {hasReme? <span className={`d-flex flex-column flex-md-row gap-2`}>
                                <TagStuRemePass {...{t, fill:1, pass:!hasFailReme}}/>
                            </span>:''}
                        </span>
                        <span className={`d-flex flex-column flex-md-row gap-3`}>
                            <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                                <span className={`semi-bold fs-8`}>{
                                    t(isReme? 'assignment-mastery-score': 'assignment-answered')}</span>
                                <span className={`d-flex align-items-end gap-2 semi-bold`}>{isReme 
                                    ?<span  className={`fs-4 line-height-normal`}>{toInt(passValue)}%</span>
                                    :<><span className={`fs-4 line-height-normal`}>{MStat?.hasAnswered||0}</span>
                                    <span>/</span>
                                    <span>{QCnt}</span></>
                                }</span>
                            </span>
                            <span className={"vr d-none d-md-block"}/>
                                <span className={`d-flex flex-column align-items-end align-items-md-center`}>
                                <span className={`semi-bold fs-8 text-nowrap`}>{
                                    t(isReme? 'assignment-follow-up-score': 'assignment-score')}</span>
                                <span className={`d-flex align-items-end gap-2 semi-bold`}>
                                    <span className={`fs-4 line-height-normal`}>{
                                        isReme?  (wSub? toPercent1(stScore, EMark): "--") + '%'
                                        : usrMark
                                    }</span>
                                    <span>/</span>
                                    <span>{isReme? '100%': toInt(EMark)}</span>
                                </span>
                            </span>
                        </span>
                    </div>
                </span>
            </span>
        </div>
    </div>
    </div>;
//    }
});
export default CardStudentAssignmentCard;

export const GetStudentActionButton = ReduxBind(props => { //base on poc getStudentActionButton
    const { dispatch, clickAsm, clickAsmRpt, clickAsmReme } = props;
    const { isReme, hasReme, usrPass, } = props;
    //const {layout = "in-list"} = props;
    //const LL = layout === "in-list";
    const {layout = "list"} = props;
    const LL = layout === "list";
    
    const [t] = useUILang();
    const asm = toObj(props.asm);
    const work = toObj(props.work);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(asm?.Exer);
    const attTimes = toInt(asm.attTimes);
    // manual mark question can only try once
    const canRetry = (!hasM) && ((attTimes === 0) || (attTimes > toInt(work._attemp)));
    const s = asm.AState;
    const w = work.WState;
    const today = timeStampNowGMT();
    const click = e => { stopEvent(e); clickAsm(asm.assignId); }; 
    const clickRpt = e => { stopEvent(e); clickAsmRpt(asm.assignId); }; 
    const clickReme = e => { stopEvent(e); clickAsmReme(asm.assignId); }; 
    const bprops = {t, click};
    // not submitted -> start/resume
    // pending result, submitted -> retry (resume?)
    // result published, submitted -> reports
    const closeReset = asm?.AResume === _ARS_CLOSE_RESET;
    const aExpired = daysDiff_2min(today, asm?.time2beEnd) < 0;    
    const APub =  (s === _AST_PUBLISHED);
    const AIP = (s === _AST_IN_PROGRESS);
    const AWaitR = (s === _AST_WAIT_RESULT);
    const wSub = (w === _WST_SUBMIT);
    const WResume = (w === _WST_RESUME);
    if (s===_AST_PENDING) return '';

    return <span className={`d-flex gap-2 ${LL? "flex-wrap" : "flex-wrap flex-lg-nowrap"} justify-content-end`}>
        {isReme? <>
            {wSub && (!usrPass)?  <CpRetryCTA {...bprops} />: ''}
            {wSub? <CpViewCTA {...bprops} />: <CpStartCTA {...bprops} />}
        </>:<>
            {hasReme? <CpRemeCTA {...{...bprops, key:'remBtn', click: clickReme}} />:''}
            {
            (AIP && ((!w) || (closeReset && WResume)))? <CpStartCTA {...bprops} />:
            (AIP && !wSub && !closeReset)? <CpResumeCTA {...bprops} />:
            ((AIP && wSub && canRetry) || (AIP && closeReset && WResume))? <CpRetryCTA {...bprops} />:
            (APub) ? <CpReportsCTA {...{...bprops, click: clickRpt}} />:
            (((APub || aExpired || AWaitR) && (!wSub)) || (AIP && wSub && !canRetry) || (AWaitR && wSub))?<CpViewCTA {...bprops}/>:
            (false&&[_AST_IN_PROGRESS, _AST_WAIT_RESULT].includes(s) && (wSub))? <CpViewCTA {...bprops} isDebug={1}/>:
            ''}
        </>}
    </span>;

});
//(APub && wSub) ? <CpReportsCTA {...{...bprops, click: clickRpt}} />:
const CpRetryCTA = (props) => { // base on poc RetryCTA.tsx
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-retry")} iconPath={IconList.assignment.retry}
        className={`CpRetryCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />
};

const CpResumeCTA = (props) => { // base on poc ResumeCTA.tsx
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-resume")} iconPath={IconList.assignment.resume}
        className={`CpResumeCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />
};

const CpReportsCTA = (props) => { // base on poc ReportsCTA.tsx
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-reports")} iconPath={IconList.assignment.reports}
        className={`CpReportsCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />
};

const CpRemeCTA = props => { //base on poc ReportsCTA
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-follow-up-exercise")} iconPath={IconList.assignment.followUpExercise}
        className={`CpRemeCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />
};

const CpStartCTA = props => {
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-start")} iconPath={IconList.assignment.start}
        className={`CpStartCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />;
};

const CpViewCTA = props => {
    const {t, click, isDebug = 0} = props;
    return <CpActionButton title={isDebug?"debug view":t("assignment-cta-view")} iconPath={IconList.assignment.preview}
        className={`CpViewCTA btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />;
};

const CpResultCTA = props => {
    const {t, click} = props;
    return <CpActionButton title={t("assignment-cta-result")} iconPath={IconList.assignment.details}
        className={`btn exercise-action-btn gap-2 rounded semi-bold user-select-none py-0`}
        onClick={click} />;
};

export const asm2PPercent = (passUnit, passValue, EScore) => (passUnit === 'p')? passValue: (EScore? ((passValue * 100) / EScore): 100);  
export const asm2PScore = (passUnit, passValue, EScore) => (passUnit === 'm')? passValue: ((EScore * passValue) / 100);  

export const stuPassValue = (asm, stuId) => {
    const {passValue, remeLvStus, remeLvPasss, } = toObj(asm);
    const stuLvs = toUniIdAry(objEntries(remeLvStus)
        .filter(e => toAry(e[1]).includes(stuId))
        .map(e => e[0]));
    const stuPVs = objEntries(remeLvPasss).filter(e => stuLvs.includes(e[0])).map(e => toInt(e[1]));
    const pv = toInt(aryLen(stuPVs)? Math.max(...stuPVs): passValue);
//console.log('stuPassValue', {stuId}, {remeLvStus, passValue, remeLvPasss}, {asm});
    return pv;
}
export const stuPassAsm = (asm, mark) => {
    if(mark?.MState !== _MST_MARKED) return false; // always fail if not marked
    if(!asm?.APass) return true; //alway pass the cant fail

    const {Exer, passUnit,} = toObj(asm);
    const {aScore, mScore} = toObj(Exer);
    const EScore = toInt(aScore) + toInt(mScore); //|| toInt(EScore) ;

    const stuId = mark.userId;
    const pv = stuPassValue(asm, stuId);

    const passMark = asm2PScore(passUnit, pv, EScore); 
    const stScore = toInt(mark?.MStat?.stScore);
    return stScore >= passMark;
};

