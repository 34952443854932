import React, {useContext, useMemo, useEffect, useState} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { ReduxBind } from "../../saga/ReduxState";
import {Accordion, AccordionContext, useAccordionButton} from "react-bootstrap";
import CpIco from "../_components/CpIco";
import { CpSettingModalSection, CpSettingModalTabContainer } from "../_components/CpSettingModalTabCps";
import CpDropdown, { ent2DropdownItem, obj2DropdownItem, sortEnt } from "../_components/CpDropdown";
import { SIZE } from "../_components/CpExPFConst";
import CpActionButton from "../_components/CpActionButton";
import TabPerformanceOverview from "./TabPerformanceOverview";
import TabQuestionPerformance from "./TabQuestionPerformance";
import TabPerformanceDetailChart from "./TabPerformanceDetailChart";
import TabPerformanceMetadataSetsChart from "./TabPerformanceMetadataSetsChart";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { aryLen, objKeys, toAry, toObj, toStr, toUniAry } from "../../libs/libType";
import { atWarnNoDot, preJS, preJSUnsort, unuseMemo } from "../../AppExPf/AppUtil";
import { _AST_IN_PROGRESS, _AST_PUBLISHED } from "../../consts/ATConstsAssignment";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { toIdStr, toUniIdAry, toUniIdSet } from "../../consts/ATValidate";
import { _ExCtType_Qtn } from "../../consts/ATValidateEcnts";

import CpSwitchComponent from "../_components/CpSwitchComponent";
import CpTeacherDownloadExcelCTA from "./CpTeacherDownloadExcelCTA";
import { mSetHasSubj, } from "../EPExercise/CpMetadataEditorCTA";
import { MSetGroupType, buildTagMetaEnts } from "../EPLibrary/LayerExerFilter";
import { _ATMetaTransByCode, _ATMetaTypeByCode } from "../../consts/ATMetaTypes";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { exerHasMark } from "../EPAssign/Tags/TagMarkingTag";
import { useEditPassValue } from "../EPAssign/TabAssignmentSettingBehaviour";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { xapiPost } from "../EPLibrary/PageLibrary";
import { expf2 } from "../../consts/ATSysQType";
import ChartThrAsmReme from "./ChartThrAsmReme";
import { normalizeSMIdStr } from "../../consts/ATValidateMetaSet";
import { RemeLvPre, RemeLvPro, RemeLvSame } from "../../consts/ATValidateReme";
import { mkEQSumFake } from "../../AppExPf/ATExerEdit/TabExerSetting";
import { exerQIds, exerUniQIds } from "../../consts/ATValidateExer";
import { pickSMByRemeLv} from "../../AppExPf/ATExerEdit/TabExerNextStep";
import { TART_detail, TART_meta, TART_over, TART_qtn, TART_reme } from "../EPReports/PageReports";
import { objEntries } from "../../libs/libType";
import { formatTimeStampHK, timeStampNowGMT } from "../../libs/libTime";
import { downloadBase64 } from "../../libs/libDownload";

const TabTeacherAssignmentReport = ReduxBind(props => { //base on poc TeacherAssignmentReport
    const { dispatch, reload } = props; 
    const { remeAsms, ex, qtns, useCacheQtns, works, marks, asmMarking} = props;
    const asm = toObj(props.asm);
    const assignId = toIdStr(asm.assignId);
    const Exer = toObj(ex);
    const {hasA, hasM, aQCnt, mQCnt, aScore, mScore} = exerHasMark(Exer);

    const [t, uiEn, lang, setLang, ut, t2] = useUILang();
    //const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(validLang(asm.ALang));

    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    const [ cache2d, useGetCache ] = useCaches(props);
    
    const classId = toStr(asm.classId);
    const students =  useGetCache('classStudents', classId);

    const subjMetas =  useGetCache('subjMetas', '*');

    const [ QIds, SMIds, rptMSetEnts, rptJEnts ] = useMemoCurr(Exer, qtns, tagMSets, useTagMSets, subjMetas);

    const APub = (asm.AState === _AST_PUBLISHED);

// ---- ---- Reme Opts ---- ---- #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### 
    const myRemes = props.remeAsms;
    const hasReme = expf2 && APub && toAry(myRemes).length; 
    const hasRemeRule = expf2 && (hasReme || aryLen(Exer.ERemes));
    
    const remeLvDDI = useMemo(() => remeLv2DDI(t), [uiEn]);
    const [l, setRemeLv] = useState();
    const rptRLv = remeLvDDI[l]? l: objKeys(remeLvDDI)[0];

    const uniQIds = useMemo(() => exerUniQIds(ex), [ex]);
    useCacheQtns(uniQIds);
    const EQSum = useMemo(() => mkEQSumFake(ex, qtns), [ex, qtns]);
    const [escore, qids, AQCnt, MQCnt, ATypeQs, ATGroupQs, MTypeQs, MTGroupQs, AScore, MScore, EQMetas, EQMetaPres, EQMetaPros, minQs, ] = toAry(EQSum);

    const [SMIdPairs, remeMSetIds] = useMemo(() => pickSMByRemeLv(rptRLv, EQMetas, EQMetaPres, EQMetaPros), [EQSum, rptRLv]);
    
    const [remeMSets, remeMSetEnts] = useMemoMSetEnts(tagMSets, useTagMSets, remeMSetIds); 
    const remeJEnts = useMemoMSetEnts2JEnts(remeMSetEnts, subjMetas, 'remeJEnts');
    //console.log('TabTeacherAssignmentReport', {remeMSetEnts, remeJEnts});

// ---- ---- Reme Opts ---- ---- #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### 
//const remeJDDI = useMemo(() => ent2DropdownItem(toAry(remeJs).map(j => [j.metaSetId+','+j.metaId, ut(j.nameEn, j.nameCt)])), [remeJs, uiEn]);  
//const remej = remeJDDI[MSubj]? MSubj: objKeys(remeJDDI)[0];
//const remeJMsets = toAry(remeMSets).filter(s => mSetHasSubj(s, remej));
//const [remeTMSets, remeSMId2d] = mapTMSets(SMIdPairs, remeMSets);

// ---- ---- Reme Opts ---- ---- #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### 

    const rTypeDDI = useMemo(() => ThrAsmRptTypeDDI(t, hasRemeRule), [uiEn]);
    const {rt, setRType} = props;
    const rType = rTypeDDI[rt]? rt: objKeys(rTypeDDI)[0];
    const rptTypeReme = (rType === TART_reme);
    const rptTypeCurr = (rType === TART_meta) || rptTypeReme; 

    const jSrcEnts = rptTypeReme? remeJEnts: rptJEnts;
    const rptJDDI = useMemo(() => ent2DropdownItem((jSrcEnts).map(([i, j]) => [i, ut(j.nameEn, j.nameCt)])), [jSrcEnts, uiEn]);
    const rptJs = useMemo(() => Object.fromEntries(jSrcEnts), [jSrcEnts]); 
    const [rj, setRptJ] = useState('');
    const rptJ = rptJs[rj]? rj: toStr(jSrcEnts[0]?.[0]);

    const srcMSetEnts = rptTypeReme? remeMSetEnts: rptMSetEnts;
    const JMSetEnts = useMSetEntsByJ(rptJ, srcMSetEnts);
    const [rptMType, setRptMType, typeMSets, MSTypeDDI, hasMSTypeDDI]= useTypeByMSetEmts(JMSetEnts, uiEn, t);
    const [rptMSId, setRptMSet, typeMSetEnts, MSetDDI, hasMSetDDI] = useRptMSet(rptMType, JMSetEnts, uiEn, ut);

    const clickDL = async () => { 
        //setShow(prev => true); await delay(3000);
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getAssignmentReport', {assignId, rType} ); //jira: EX-1665
        const fileData = res.fileData; //base64
        if(fileData){
            const timeStr = formatTimeStampHK(timeStampNowGMT(), 'YYYY-MM-DD_HH.mm.ss');
            downloadBase64(fileData, 'AssignmentReport_'+timeStr+'.xlsx');
        } 
        //await delay(1000); setShow(prev => false);

        //const rps = toObj(res?.rps);
        //const {overQtn, stuQtn} = rps;
        //const stuMap = res?.stuMap;
        //const rows = toRptExc1(overQtn, stuQtn, stuMap);
    };

//Asm Pass ==== #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
    const [APass, setAPass]= useState(asm.APass);
    const filpAPass = e => setAPass(p => p? 0: 1 );
    const passUnitDDI = useMemo(() => obj2DropdownItem({ p: t("percentage"), m: t("marks"), }), [lang]);
    const [u, setPassUnit] = useState(asm.passUnit);
    const passUnit = passUnitDDI[u]? u: objKeys(passUnitDDI)[0];
    const [passValue, setPassValue] = useState(asm.passValue);
    const {exitEdPassVal, changePassVal, maxPassVal} = useEditPassValue(passUnit, aScore+mScore, passValue, setPassValue);
    const lockPVal = APub || (!APass);
    const visPVal = APass? passValue: '-';
    const saveAsmPass = async () => {
        if((passUnit === asm.passUnit) && (APass === asm.APass) && (passValue === asm.passValue)) return;
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', { assignId, newPass:{passUnit, APass, passValue} });
        reload();
    };

    const [fields, setFields] = useState({passUnit, passValue, APass});
    const modPass = () => { 
        setFields({passUnit, APass, passValue}) 
        saveAsmPass();
    };

    const fTxt = unuseMemo(() => {
        return [rTypeDDI[rType]?.t,
        rptTypeCurr && ('(' + toStr(rptJDDI[rptJ]?.t) + ')')].filter(t => t).join(' '); 
        /*
        return [rTypeDDI[rType]?.t,
        rptTypeCurr && ('(' + toStr(rptJDDI[rptJ]?.t) + ')'), 
        fields.APass && t("with-passing-grade").replace("%s", toStr(fields.passValue)),
        fields.APass && (passUnitDDI[fields.passUnit]?.t)].filter(t => t).join(' ');
        */
    }, [fields]);

// Other Misc ==== #### #### #### #### #### #### #### #### #### #### #### #### #### #### ####
    useEffect(() => {
        //if(!(rptJ /*&& rptMType && rptMSId*/)) return; //preLoad params;        
        xapiPost(dispatch, 'thrAsmRpt', {assignId, rType, rptJ, rptMType, rptMSId, rptRLv}) 
    }, [assignId, rType, rptJ, rptMType, rptMSId, rptRLv]);

    const rptProps = {...props, asm, Exer, QIds, qtns, students, works, marks, asmMarking,
        fields, rptJ, rptMType, rptMSId, 
        SMIds, typeMSets};
    const remeRptProps = {...rptProps, rptRLv, remeAsms};

    //const passScoreEle = () => rptTypeReme? '': <div className={"d-flex flex-column flex-xl-row align-items-start align-items-xl-center gap-2"}></div>    
    // xl => lg
    const passScoreEle = (classStr) => rptTypeReme? '': <div className={classStr}>
        <span className={"semi-bold"}><span className={"d-flex gap-2 semi-bold user-select-none"} role={"button"} onClick={APub? undefined: filpAPass}>
            {APub
            ? `${t("passing-grade")}:`
            :<CpSwitchComponent variant={"exercise-third-btn pe-none"} size={SIZE.XS} readOnly={true} checked={APass} label={`${t("passing-grade")}:`}/>
            }
        </span></span>
        <div className={"d-flex flex-column flex-lg-row gap-2"}>
            <span className={"d-flex gap-2 mb-3 mb-lg-0"}>
                {lockPVal
                    ?<input {...{style:{width: "60px"}, type:"text", value:visPVal, disabled:true, readOnly:true}} />
                    :<input {...{style:{width: "60px"}, type:"number", min:0, max:maxPassVal, value:toStr(passValue),
                        onBlur: exitEdPassVal, 
                        onChange: changePassVal,
                        //onChange: (e) => {setPassValue(toStr(toInt(e.currentTarget.value)))}                                                     
                        }} />
                }
                <span style={{width: "130px"}} className={"d-flex"}>
                    <CpDropdown className="w100" {...{className:`${APass? '': 'bg-dim-100 pe-none'}`, items: passUnitDDI, idx: passUnit, cb: setPassUnit, enable: !APub }}/>
                </span>
            </span>
            {APub? '':
            <span className={"d-flex mb-3 mb-lg-0"}>
                <CpActionButton className={"btn-exercise-third-btn gap-2 rounded semi-bold user-select-none btn"} 
                    onClick={modPass} title={t("update")} hiddenText={false} />
            </span>}
        </div>
    </div>;

    return <CpSettingModalTabContainer paddingX={0}><CpSettingModalSection paddingX={0} className={"TabTeacherAssignmentReport w-100"}>
        <div className={"TabTeacherAssignmentReport w-100"}>
            <div className={"rounded-light border bg-dim-100 mb-3"}>
                <Accordion defaultActiveKey="0" className={"w-100"}>
                    <div className={"d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100"}>
                        <OpenCTA eventKey="0">{fTxt}</OpenCTA>
                        <div className="d-flex gap-3">
                            {passScoreEle(markClass1)}
                            <CpTeacherDownloadExcelCTA {...{clickDL}}/>
                        </div>
                    </div>
                    <Accordion.Collapse eventKey="0" className={"bg-transparent"}><div>
                        <hr className={"mt-0 border-dim-400"}/>
                        <div className={"d-flex flex-column flex-xl-row flex-wrap gap-2 justify-content-between px-2 pb-2"}>
                            {passScoreEle(markClass2)}
                            <div className={`d-flex flex-wrap flex-column flex-xl-row align-items-start align-items-xl-center gap-2 mb-3 mb-xl-0`}>
                                <span><span className={"semi-bold"}>{t('report-type')}:</span></span>
                                <span className={"d-flex flex-wrap gap-2"}>
                                    <span className={"d-flex flex-wrap gap-2"}>
                                        <span style={{width: "250px"}} className={"d-flex w-sm-100"}>
                                            <CpDropdown id='rTypeDD' className="w100" {...{items:rTypeDDI, idx:rType, cb:setRType}} />
                                        </span>
                                    </span>
                                </span>
                            </div>
                            {rptTypeCurr? <div className={`d-flex flex-wrap flex-column flex-xl-row align-items-start align-items-xl-center gap-2 mb-3 mb-xl-0`}>                            
                                <span><span className={"semi-bold"}>{t("report-filter")}:</span></span>
                                <span className={"d-flex flex-wrap gap-2 align-items-center flex-column flex-xl-row"}>                                
                                    {rptTypeCurr?<span className="d-flex" style={{width: "330px"}}>
                                        <CpDropdown id='rptJDD' className="w100" {...{items:rptJDDI, idx:rptJ, cb:setRptJ}} />
                                    </span>:''}
                                    {(rptTypeCurr && hasMSTypeDDI)? <span className="text-exercise-third-btn">
                                        <span className="d-none d-xl-block"><CpIco src={IconList.general.next} /></span>
                                        <span className="d-xl-none"><CpIco src={IconList.general.orderDown} /></span>
                                    </span>:''}
                                    {(rptTypeCurr && hasMSTypeDDI)? <span className="d-flex" style={{width: "330px"}}>
                                        <CpDropdown id='MSTypeDD'  className="w100" {...{items:MSTypeDDI, idx:rptMType, cb:setRptMType}} />
                                    </span>:''}
                                    {(rptTypeCurr && hasMSTypeDDI && hasMSetDDI)? <span className="text-exercise-third-btn">
                                        <span className="d-none d-xl-block"><CpIco src={IconList.general.next} /></span>
                                        <span className="d-xl-none"><CpIco src={IconList.general.orderDown} /></span>
                                    </span>:''}
                                    {(rptTypeCurr && hasMSTypeDDI && hasMSetDDI)? <span className="d-flex" style={{width: "330px"}}>
                                        <CpDropdown id='MSetDD'  className="w100" {...{items:MSetDDI, idx:rptMSId, cb:setRptMSet}} />
                                    </span>:''}
                                </span>
                            </div>:''}
                            {rptTypeReme? <div className={`d-flex flex-wrap flex-column flex-xl-row align-items-start align-items-xl-center gap-2 mb-3 mb-xl-0`}>
                                <span><span className={"semi-bold"}>{t("report-question-scope")}:</span></span>
                                <span className={"d-flex flex-wrap gap-2"}>
                                    <span style={{ width: "250px" }} className={"d-flex w-sm-100"}>
                                        <CpDropdown id='remeLvDD' className="w100" {...{items:remeLvDDI, idx:rptRLv, cb:setRemeLv}} />
                                    </span>
                                </span>
                            </div>:''}                            
                        </div>
                    </div></Accordion.Collapse>
                </Accordion>
            </div>
{preJSUnsort({rType, rptJ, rptMType, rptMSId, rptRLv })}
{preJS({hasReme, hasRemeRule})}
            <div className={"report-min-height mb-5 fade-in-container"}>{
                rptTypeReme? ( APub? <ChartThrAsmReme {...remeRptProps} />: atWarnNoDot("warning.warning_follow_up_report_before_published",t))
                :rptTypeCurr? <TabPerformanceMetadataSetsChart {...rptProps}/>
                :rType === TART_detail? <TabPerformanceDetailChart {...rptProps} />
                :rType === TART_qtn? <TabQuestionPerformance {...rptProps} />
                :<TabPerformanceOverview {...rptProps} /> /* rpt === 'over' */ 
                // rptTypeCurr? <TabPerformanceMetadataSetsChartV2 {...rptProps}/>: '' }{
            }</div>
        </div>
    </CpSettingModalSection></CpSettingModalTabContainer>;
});
export default TabTeacherAssignmentReport;

const markClass1 = "d-none d-xl-flex flex-column flex-xl-row align-items-start align-items-xl-center gap-2";
const markClass2 = "d-flex flex-column flex-xl-row align-items-start align-items-xl-center gap-2 d-xl-none";

function OpenCTA({children, eventKey, callback}) {
    const {activeEventKey} = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey),);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <span role="button" onClick={decoratedOnClick} className={"d-flex gap-2 align-items-center text-wrap"}>
            <span className={"text-wrap"}>{children}</span>
            <span className={"fs-5"}><CpIco src={isCurrentEventKey ? IconList.general.arrowUp : IconList.general.arrowDown}/></span>
        </span>
    );
};

const ThrAsmRptTypeDDI = (t, rememOpt) => ent2DropdownItem([
    [TART_over, t("teacher-report-performance-overview")],
    [TART_qtn, t("teacher-report-question-performance")],
    [TART_detail, t("teacher-report-performance-details")],
    [TART_meta, t("teacher-report-metadata-analysis")],
    rememOpt && [TART_reme, t("teacher-report-follow-up-exercise-report")],
].filter(m => m));

const remeLv2DDI = t => ent2DropdownItem([  
    [RemeLvSame, t('assignment-status-option-follow-up-same')], //meta-level-same
    [RemeLvPre, t('assignment-status-option-follow-up-pre')], //meta-level-pre
    [RemeLvPro, t('assignment-status-option-follow-up-pro')], //meta-level-pro
]);

const useMSetsMSubjEnts = (subjMetas, MSets, caller) => {
    const subjMetaEnts = useMemo(() => toAry(subjMetas).map(s => [s.metaSetId+','+s.metaId, s]), [subjMetas]); 
    const MSetSjSet = useMemo(() => {
        const msmj2d = toAry(MSets).map(mset => mset.doReport? normalizeSMIdStr(mset.MSReports): []);
        return toUniIdSet([].concat(...msmj2d))
    }, [MSets]);
//console.log('useMSetsMSubjEnts '+caller, {subjMetaEnts, MSetSjSet, MSets});
    return useMemo(() => sortEnt(toAry(subjMetaEnts).filter(e => MSetSjSet.has(toAry(e)[0]))), [subjMetaEnts, MSetSjSet]);
};
export const useMemoMSetEnts2JEnts = (rptMSetEnts, subjMetas, caller) => {
    const MSets = useMemo( () => rptMSetEnts.map(kv => kv[1]), [rptMSetEnts]);
    const rptJEnts = useMSetsMSubjEnts(subjMetas, MSets, caller);
//console.log('useMemoMSetEnts2JEnts ', {rptMSetEnts, subjMetas, MSets, rptJEnts});
    return rptJEnts;
}
export const useMemoMSetEnts = (tagMSets, useTagMSets, MSetIds) => {
    const msetMap = useTagMSets(MSetIds);
    const rptMSetEnts = useMemo(() => buildTagMetaEnts(msetMap), [MSetIds, tagMSets]);
//console.log('useMemoMSetEnts', {MSetIds, tagMSets, msetMap, rptMSetEnts});
    return [msetMap, rptMSetEnts];
};
export const useMemoCurr = (Exer, qtns, tagMSets, useTagMSets, subjMetas) => {
    const QIds = useMemo(() => exerQIds(Exer), [Exer]);
    const uniQs = useMemo(() => toUniAry(QIds).map(i => qtns[i]).filter(q => q), [QIds, qtns]);
    const SMIds = useMemo(() => toUniIdAry([].concat(...uniQs.map(q => toAry(q.QDisplayMetas)))), [uniQs]);  
    //const SMPreIds =  useMemo(() => toUniIdAry([].concat(...uniQs.map(q => toAry(q.QMetaPres)))), [uniQs, qtns]);  //expf2 
    //const SMProIds =  useMemo(() => toUniIdAry([].concat(...uniQs.map(q => toAry(q.QMetaPros)))), [uniQs, qtns]);  //expf2   
    const MSetIds = useMemo(() => toUniIdAry(SMIds.map(sm => toStr(sm).split(',')[0])), [SMIds]);    
    
    const [rptMSets, rptMSetEnts] = useMemoMSetEnts(tagMSets, useTagMSets, MSetIds); 
    const rptJEnts = useMemoMSetEnts2JEnts(rptMSetEnts, subjMetas, 'useMemeoCurr');
    return [QIds, SMIds, rptMSetEnts, rptJEnts]; 
};

export const useMSetEntsByJ = (rptJ, rptMSetEnts) => {
    return useMemo(() => rptJ? rptMSetEnts.filter(([i,m]) => mSetHasSubj(m, rptJ)): [], [rptJ, rptMSetEnts]);
}

export const useTypeByMSetEmts = (MSetEnts, en, t) => {
    const typeMSets = useMemo(() => MSetGroupType((MSetEnts)), [MSetEnts]); 
    const MSTypeDDI = useMemo(() => ent2DropdownItem(sortEnt(objKeys(typeMSets).map(MSType => [MSType, t(_ATMetaTransByCode(MSType))]))), [typeMSets, en]);
    const hasMSTypeDDI = MSTypeDDI && (objEntries(MSTypeDDI).length > 0);
    const [_t, setRptMType] = useState();
    const rptMType = MSTypeDDI[_t]?_t: objKeys(MSTypeDDI)[0];
    
    return [rptMType, setRptMType, typeMSets, MSTypeDDI, hasMSTypeDDI]; 
};

export const useRptMSet = (rptMType, rptMSetEnts, uiEn, ut) => {
    const typeMSetEnts = useMemo(() => toAry(rptMSetEnts).filter(e => (e[1].MSType === rptMType)), [rptMType, rptMSetEnts,]); 
    const MSetDDI = useMemo(() => ent2DropdownItem(typeMSetEnts.map(([k,v]) => [k, ut(v.MSNameEn, v.MSNameCt)])), [typeMSetEnts, uiEn]);
    const hasMSetDDI = MSetDDI && (objEntries(MSetDDI).length > 0);
    const [_s, setRptMSet] = useState();
    const rptMSId = MSetDDI[_s]? _s: objKeys(MSetDDI)[0];
    return [rptMSId, setRptMSet, typeMSetEnts, MSetDDI, hasMSetDDI];
};

