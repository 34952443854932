export type CONTENT_TYPE =
    "image"
    | "box"
    | "text"
    | "audio"
    | "link"
    | "video"
    | "rubric"
    | "passage"
    | "draw"
    | "file"

export const ContentTypeList = [
    {
        translate: "section",
    },
    {
        translate: "image",
    },
    {
        translate: "text",
    },
    {
        translate: "video",
    },
    {
        translate: "audio",
    },
    {
        translate: "hyperlink",
    }
]

export enum ExerciseContentType {
    "EXERCISE_CONTENT_TYPE_QUESTION" = "EXERCISE_CONTENT_TYPE_QUESTION",
    "EXERCISE_CONTENT_TYPE_RUBRIC" = "EXERCISE_CONTENT_TYPE_RUBRIC",
    "EXERCISE_CONTENT_TYPE_IMAGE" = "EXERCISE_CONTENT_TYPE_IMAGE",
    "EXERCISE_CONTENT_TYPE_TEXT" = "EXERCISE_CONTENT_TYPE_TEXT",
    "EXERCISE_CONTENT_TYPE_VIDEO" = "EXERCISE_CONTENT_TYPE_VIDEO",
    "EXERCISE_CONTENT_TYPE_AUDIO" = "EXERCISE_CONTENT_TYPE_AUDIO",
    "EXERCISE_CONTENT_TYPE_LINK" = "EXERCISE_CONTENT_TYPE_LINK"
}

export enum ExerciseContentActionType {
    "CREATE_QUESTION" = "CREATE_QUESTION",
    "SEARCH_QUESTION" = "SEARCH_QUESTION"
}

export interface ExerciseToolbarAction {
    action: ExerciseContentType | ExerciseContentActionType
}

export const getContentType = (type: string) => {
    switch (type) {
        case "question":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_QUESTION
            break;
        case "section":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_RUBRIC
            break;
        case "text":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_TEXT
            break;
        case "image":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_IMAGE
            break;
        case "audio":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_AUDIO
            break;
        case "video":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_VIDEO
            break;
        case "link":
            return ExerciseContentType.EXERCISE_CONTENT_TYPE_LINK
            break;
    }
    return ""
}