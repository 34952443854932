import React, {useState} from 'react';
import { isAry, nestFlaten, nestFlaten1, objSort, toAry, toObj, toStr, tryParseJson } from '../libs/libType';
import { QP_P, QP_DR, QP_PU, QP_PP } from '../consts/ATConsts';
import * as UI from '../libs/libUI';
import { urlPush_Replace } from '../saga/urlPush.saga';
import { debugMode } from '../saga/ReduxState';
import { popConfirm } from './components/CpPopup';
import { formatDate, timeStampGMTToHk } from '../libs/libTime';
import { Modal } from 'react-bootstrap';
import { __MCAT_ATG, __MCAT_ATI } from '../consts/ATQtnAnsTypeMCQ';
import { Buffer } from 'buffer';
import CpMessageBox from '../AppExPFUser/_components/CpMessageBox';

export const unuseMemo = (func, ary) => func();

var _autoId = 0;
export const autoId = () => 'i'+(++_autoId);
export const forceRenew = () => autoId();

//common helper function the use lib and sgas  ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const ATHr = prop => <div style={{height:'2px', margin:'8px 2px', backgroundColor:'#ccf'}}></div> 

export const PopA = href => <PopAJSX href={href} />;
export const PopAJSX = ({href}) => <a rel="noopener noreferrer" target="figma" href={href} >{href} </a>; 

//common helper function the use lib and sgas  ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const clickUrl = (dispatch, path) => (e) =>{ UI.stopEvent(e); dispatch(urlPush_Replace(path)); };
export const clickConfirm = (dispatch, cfmMsg, act) => e => {UI.stopEvent(e); popConfirm(dispatch, 0, cfmMsg, act); };

//Pub  / Draft  ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const QDRTxt = r2p => r2p? 'Requested to Publish': 'Draft';
export const QPUTxt = unp => unp? 'Unpublished': 'Published';
export const QDPTxt = (pub, r2p, unp) => pub? QPUTxt(unp): QDRTxt(r2p);

export const QDRCol = r2p => r2p? '#db4': '#ddd';
export const QPUCol = unp => unp? '#f86' : '#afa';
export const QDPCol = (pub, r2p, unp) => pub? QPUCol(unp) : QDRCol(r2p);
export const QDPCol2 = (dp, drpu) => (dp===QP_P)? QPUCol((drpu===QP_PU)) : QDRCol((drpu===QP_DR));

export const langDisplay = (en, ch) => <div>{en? <span className="metaLangEn">En</span>: ''} {ch? <span  className="metaLangCh">繁中</span>: ''}</div>;

export const dpDisplay = (dd, ddrpu, pp, pdrpu) => {
	const [ r2p, unp ] = [ddrpu === QP_DR, pdrpu === QP_PU];
	//const pubcss = 'metaPub '+(pub? '': ' metaUnpub ');
	return <div className="metaDPdisplay">
		<div style={{backgroundColor:QDRCol(r2p)}} className={'metaDraft ' + (!dd? "metaHide": "")}>{dd&&("D "+dd)}</div>|
		<div style={{backgroundColor:QPUCol(unp)}} className={'metaPub ' + (!pp? "metaHide": "")}>{pp&&((unp? 'Up ': 'P ')+pp)}</div>
	</div>;
};

// Lang En / Ct  ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const safeEn = (wantEn, hasEn, hasCt) => hasCt? (hasEn? wantEn: 0) :1;

//common UI Elements ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const LoadIco = (size, style = {}) => <dotlottie-player autoplay loop={false} src={"/static/img/lottie/ep_loading.lottie"} style={{...style, width:size}} />;
export const Loader = (size, style = {}) => <center className="h100">{LoadIco(size, style)}</center>;

//const r3 = ['(%s) %s %s %s','key1', 'value only 1', 'key2', 'value only 2'];
const regErr = (es, _t=0) => {
    const t = (v) => _t?_t(v):v;
    
	if (isAry(es)) {
   		const [body, ...paras] = es;
        let result = toStr(body);
        if (!paras.length) return t(result);
        for (let ii=0; ii<paras.length; ii++) {
            result = result.replace('%s', t(paras[ii]));
        };
        return result;
    } else {
    	return t(es);
    };
};

//export const ATErrsDiv = (es) => <div>{Object.values(toObj(es)).map((e, i) => <div key={i} className='cf00 f12'>{e}</div>)}</div>;
export const ATErrsDiv = (es, className='') => <div className={className}>{toAry(nestFlaten(es)).map((e, i) => <div key={i} className='cf00 f12'>{e}</div>)}</div>;
export const ATErrsDiv2 = (es, className='', t) => <div className={className}>
{toAry(nestFlaten1(es)).map((e, i) => <div key={i} className='cf00 f12'>{regErr(e,t)}</div>)}
</div>;
export const ATErrsDiv3 = (es, className='', t) => <div className={className}><ul>
{toAry(nestFlaten1(es)).map((e, i) => <li key={i}>{regErr(e,t)}</li>)}
</ul></div>;

export const atErrsDiv = (es, className='', t, border=1) => <CpMessageBox {...{className}}
    item={{mode:"error", style:border?"list":"noborder", content:{title:"", list:toAry(nestFlaten1(es)).map((e, i) => regErr(e,t))}}}
/>;
export const atWarnsDiv = (es, className='', t, border=1) => <CpMessageBox {...{className}}
item={{mode:"warning", style:border?"list":"noborder", content:{title:"", list:toAry(nestFlaten1(es)).map((e, i) => regErr(e,t))}}}
/>;

//{toAry(nestFlaten1(es)).map((e, i) => <div key={i}>{regErr(e,t)}</div>)}

export const aTErrDiv1 = (v, t=0) => { return v?<div style={{color:'#f00'}}>{regErr(v,t)}</div>:'';};
export const aTErrTxt1 = (v, t=0) => { return v?<span style={{color:'#f00'}}>{regErr(v,t)}</span>:'';};
export const aTWarnDiv1 = (v, t=0) => { return v?<div style={{color:'#b80'}}>{regErr(v,t)}</div>:'';};
export const aTWarnTxt1 = (v, t=0) => { return v?<span style={{color:'#b80'}}>{regErr(v,t)}</span>:'';};

// warning/error msg no border, no dot VVVVVVVVVVVV
export const atErrTxt = (v, t=0) => { return v?<CpMessageBox item={{mode:"error", style:"spanTxt", content:{title:regErr(v,t)}}} />:''; };
export const atErrDiv = (v, t=0) => { return v?<CpMessageBox item={{mode:"error", style:"divTxt", content:{title:regErr(v,t)}}} />:''; };
export const atWarnTxt = (v, t=0) => { return v?<CpMessageBox item={{mode:"warning", style:"spanTxt", content:{title:regErr(v,t)}}} />:''; };
export const atWarnDiv = (v, t=0) => { return v?<CpMessageBox item={{mode:"warning", style:"divTxt", content:{title:regErr(v,t)}}} />:''; };
// warning/error msg no border, no dot ^^^^^^^^^^^^

// warning/error msg with border, with/without dot VVVVVVVVVVVV
export const atWarnDot = (v, t=0) => { return v?<CpMessageBox item={{mode:"warning", style:"dot", content:{title:regErr(v,t)}}} />:''; };
export const atErrDot = (v, t=0) => { return v?<CpMessageBox item={{mode:"error", style:"dot", content:{title:regErr(v,t)}}} />:''; };

export const atWarnNoDot = (v, t=0) => { return v?<CpMessageBox item={{mode:"warning", style:"unstyle", content:{title:regErr(v,t)}}} />:''; };
export const atErrNoDot = (v, t=0) => { return v?<CpMessageBox item={{mode:"error", style:"unstyle", content:{title:regErr(v,t)}}} />:''; };
// warning/error msg with border, with/without dot ^^^^^^^^^^^^^

/*
export const aTErrDiv1 = (t) => { return t?<div style={{color:'#f00'}}>{t}</div>:'';};
export const aTErrTxt1 = (t) => { return tv?<span style={{color:'#f00'}}>{t}</span>:'';};
export const aTWarnDiv1 = (t) => { return t?<div style={{color:'#b80'}}>{t}</div>:'';};
export const aTWarnTxt1 = (t) => { return t?<span style={{color:'#b80'}}>{t}</span>:'';};
*/

export const aTUIMust = <span className="redStar"> * </span>;
export const aTUITime = (timeStampGMT, format='YYYY-MM-DD HH:mm:ss') => formatDate(timeStampGMTToHk(timeStampGMT), format, false);

export const ATUICheckBox0 = (on, setter, id, lock=false, className='m4', style={}) => {
    return UI.CheckBox0(on, setter, id, lock, className, style);
};
export const ATUIText = (txt, setter, id, lock, placeHolder, className='', style={width:'80%', margin:'4px 8px'}) => {
    return lock? toStr(txt): UI.EpInputTxt0(toStr(txt), setter, id, className, style, placeHolder);
};
export const ATUILabel = (txt, id='', withStar=false, width=20,className='', style={}) => {
    return <div id={id} className={'atfieldhead ' + className} style={{minWidth:width+'px', ...style}}>{txt}{withStar?aTUIMust:''}</div>
};
export const CpATBtn = (txt, id, onclick, lock, className='', style={}) => {
    const dis = lock || (!onclick);  
    return <div id={id} className={(dis?'atuibtnlock ':'atuibtn ')+className}  style={{...style}} onClick={((!lock) && onclick) || undefined} >{txt}</div>;
};

const cssPreJs = {
    backgroundColor:'#ddd', color:'#000',
    fontSize:'10px', lineHeight:"120%", border:'1px solid #000',
    width:'60vw', padding:'2px',
    //,  margin:'2px',  
    whiteSpace:'pre-wrap', wordBreak:'break-word', overflowWrap:'break-word', 
};

export const preJSSort = (o, indent, key) => debugMode()? <PreJSX key={key||autoId()} o={objSort(o)} indent={indent} />: '';
export const preJSUnsort = (o, indent, key) => debugMode()? <PreJSX key={key||autoId()} o={o} indent={indent} />: '';
export const preJS = preJSSort;
export const preJSMAX = (o, indent, key) => <PreJSX key={key||autoId()} o={objSort(o)} indent={indent} />;
export const preJS2 = (o, indent, forceShow=0) => (debugMode() || forceShow) ? <PreJSX key={autoId()} o={o} indent={indent} />: '';
export const PreJSX = ({o, indent})  => (<pre className="preJS" style={cssPreJs} >{JSON.stringify(o, undefined, indent)}</pre>);
export const jsStr = (o, indent)  => JSON.stringify(o, undefined, indent);

const cssPop = {zIndex:99999999, position:'absolute', display:'inline-block', top:'25px', left:'0px', padding:'4px',  
    maxHeight:'60vh', overflow:'auto', width:'auto',
    backgroundColor:'#eee', boxShadow:'2px 2px 6px #888888'};

const cssHide = {display:'none'};
export const BtnPopDev = props => debugMode()? <BtnPopDevX {...props} /> :''; 
export const BtnPopDevX = props => {
    const [on, setOn] = useState(props.vis);
    return (<div style={{position:'relative', display:'inline-block', padding:'2px'}}>
        <div style={on?cssPop:cssHide}>{props.children}</div>
        {UI.Button0(props.txt||'...', UI.stopEventThen(e => setOn(o => !o)), 'btn')}
    </div>);
};
export const BtnDev = props => {
    const [on, setOn] = useState(props.vis);
    return debugMode()? <div className='w m4'>
        {UI.Button0(props.txt||'...', e => {setOn(o => !o);}, 'btn')}
        <div style={on?{}:cssHide}>{props.children}</div>
    </div>: '';
};

//common Broswer File Helper ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== 
export const readUploadText = async (file) => {
    return await new Promise((resolve) => {
        try{
            const reader = new FileReader();
            reader.onerror = err => { resolve([false, err]); }
            reader.onload = event => { 
                const target = event.target;
                resolve([target.result, false]);
            };
            reader.readAsText(file);
        }catch(e){
            resolve(false, e);
        }
    });
};
export const readUploadBinBuf = async (file) => {
    const [binstr, e] = await new Promise((resolve) => {
        try{
            const reader = new FileReader();
            reader.onerror = err => { resolve([false, err]); }
            reader.onload = event => { 
                const target = event.target;
                resolve([target.result, false]);
            };
            reader.readAsBinaryString(file);
        }catch(e){
            resolve([false, e]);
        }
    });
    //return new Buffer(binstr, 'binary');
    return [Buffer.from(binstr, "binary"), e];
};

const safeInsert = (coll, id, idkey, key, val) => {
    if(!coll[id])
        coll[id] = {[idkey]:id};
    coll[id][key] = val;
};
export const mergePubsDrafts = (pubs, drafts, key) => {
    
    const ret = {};
    toAry(pubs).forEach( ms =>  safeInsert(ret, ms[key], key, 'pub', ms) );
    toAry(drafts).forEach( ms =>  safeInsert(ret, ms[key], key, 'draft', ms) );
	return ret;
};

export const deepCopy = (src) => tryParseJson(JSON.stringify(src));
  
export const PopDL = href => <a rel="noopener noreferrer" target="figma" href={href} >[DL]</a>

//parameters: https://react-bootstrap.github.io/components/modal/
// size = 'sm', 'md', 'lg', 'xl'
export const SizeScreenMask = (children, show=true, size='md', animation=false ) => {
    return _BootstrapModal(children, show, false, size, animation);
};
export const FullScreenMask = (children, show=true, animation=false) => {
    return _BootstrapModal(children, show, true, '', animation);
};
export const _BootstrapModal = (children, show, fullscreen, size, animation) => {
    return <Modal fullscreen={fullscreen} show={show} animation={animation} size={size}
        enforceFocus={false} centered={true} scrollable={true}>
        {children}
    </Modal>
};